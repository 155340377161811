import React, { useState, useEffect } from 'react';
import CreateQuotation from './CreateQuatation'; // Import the CreateQuotation component
import "./css/QuatationList.css"
import Test from './Test';

const QuotationList = ({ toggleComponent,selectedCompany }) => {
    const [quotations, setQuotations] = useState([]);  // List of quotations
    const [selectedQuotation, setSelectedQuotation] = useState(null);  // Selected quotation
    const [loading, setLoading] = useState(true);  // Loading state
    const [error, setError] = useState(null);  // Error state

    // Fetch quotations from the API
    useEffect(() => {
        const fetchQuotations = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch('https://emkayindia.in/api/v1/quotation/all', {
                    headers: { Authorization: `Bearer ${token}` }
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch quotations');
                }
                const data = await response.json();
                setQuotations(data);  // Assuming API returns an array of quotations
                setLoading(false);    // Stop loading once data is fetched
            } catch (err) {
                setError(err.message);
                setLoading(false);  // Stop loading in case of error
            }
        };

        fetchQuotations();
    }, []);

    // Track selectedQuotation changes
    useEffect(() => {
        if (selectedQuotation) {
            console.log("Selected Quotation updated:", selectedQuotation);  // Verify that state is updated
        }
    }, [selectedQuotation]);  // This will run when selectedQuotation changes

    // Function to handle click on a quotation
    const handleQuotationClick = (quotation) => {
        setSelectedQuotation(quotation);  // Set the selected quotation data
        console.log("Quotation passed to setSelectedQuotation:", quotation);  // Verify data before setting
        //   localStorage.setItem('selectedquatation',quotation);
        // toggleComponent('QuatationList');
    };

    // Render loading, error, or quotation list
    if (loading) {
        return <div className='loading'><p>Loading quotations...</p></div>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <div className="quotation-list-container">
           {!selectedQuotation && <div>
                <h2>All Quotations</h2>

                <ul className="quotation-list">
                    {quotations.map((quotation) => (
                        <li
                            key={quotation.id}
                            onClick={() => handleQuotationClick(quotation)}  // Call function directly
                            className="quotation-list-item"
                        >
                            <strong>Quotation ID:</strong> <span>{quotation.id}</span> <br />
                            <strong>Date:</strong>{' '}
                            <span>{new Date(quotation.date).toLocaleDateString()}</span> <br />
                            <strong>Client:</strong>{' '}
                            <span>{quotation.client.name} ({quotation.client.nickname})</span> <br />
                            <strong>Company:</strong> <span>{quotation.company.companyName}</span>
                        </li>
                    ))}
                </ul>
            </div>}


            {selectedQuotation && (
                <div className="create-quotation-container">
                    {/* Pass selectedQuotation to CreateQuotation component */}
                    <CreateQuotation className="Create-quatation-QuatationList" selectedQuotationId={selectedQuotation} selectedCompany={selectedQuotation.company} />

                    {/* <Test selectedQuatation={selectedQuotation}/> */}
                </div>
            )}
        </div>
    );
};

export default QuotationList;
