import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from './button'
import Input from './Input'
import { login } from '../utils/auth'

const Login = ({ setAuthToken }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = await login(email, password);
      localStorage.setItem("token", token);
      setAuthToken(token);
      navigate("/dashboard");
    } catch (err) {
      setError("Login failed. Please check your credentials.");
    }
  };

  return (
    <div className="flex min-h-screen items-center justify-center bg-[#250656] p-4">
      <div className="w-full max-w-4xl overflow-hidden rounded-3xl shadow-2xl">
        <div className="flex flex-col md:flex-row md:h-[600px]">
          {/* Left side with gradient background and content */}
          <div className="relative flex w-full flex-col justify-between bg-gradient-to-br from-purple-600 via-purple-700 to-purple-900 p-12 text-white md:w-1/2 overflow-hidden">
            {/* Logo */}
            <div className="flex items-center z-10">
              <span className="text-4xl font-bold" style={{ fontFamily: '"Bauhaus 93", sans-serif', color: '#ffffff' }}>
                EMKAY INDIA
              </span>
            </div>
            
            {/* Welcome text */}
            <div className="my-12 text-left z-10">
              <h1 className="mb-4 text-6xl font-bold leading-tight">
                <span className="block">Welcome</span>
              </h1>
              <p className="text-xl">Sign in to<br />continue access</p>
            </div>
            
            {/* Website URL */}
            <div className="z-10">
              <p className="text-lg">www.emkayindia.in</p>
            </div>
            
            {/* Decorative shapes */}
            <div className="absolute -left-20 -top-20 h-72 w-72 rounded-full bg-blue-400 opacity-50"></div>
            <div className="absolute -bottom-24 -right-24 h-96 w-96 rounded-full bg-purple-500 opacity-50"></div>
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-48 w-48 rounded-full bg-gradient-to-br from-blue-400 to-purple-500 opacity-50"></div>
            <div className="absolute bottom-20 left-12 h-16 w-16 rounded-full bg-pink-400 opacity-50"></div>
          </div>

          {/* Right side with login form */}
          <div className="w-full bg-white p-12 md:w-1/2 flex flex-col justify-center items-center">
            <div className="w-full max-w-sm">
              <h2 className="mb-8 text-3xl font-bold text-gray-800 text-center">Sign In</h2>
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <Input
                    type="text"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="w-full border-b border-purple-500 bg-transparent px-0 py-2 placeholder-gray-500 focus:border-purple-700 focus:ring-0"
                  />
                </div>
                <div>
                  <Input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="w-full border-b border-purple-500 bg-transparent px-0 py-2 placeholder-gray-500 focus:border-purple-700 focus:ring-0"
                  />
                </div>
                <Button
                  type="submit"
                  className="group w-full rounded-md bg-purple-600 py-3 text-lg font-semibold text-white transition duration-300 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                >
                  CONTINUE
                  <svg
                    className="ml-2 inline-block h-6 w-6 transition-transform group-hover:translate-x-1"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                    />
                  </svg>
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login;