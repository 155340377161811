// import React, { useState, useEffect } from 'react';
// import "../css/CreateQuatation.css";
// import axios from 'axios';
// import { toast } from 'react-toastify';

// const CreateQuatation = ({ toggleComponent }) => {
//   const [billingProducts, setBillingProducts] = useState([{ name: '', quantity: '', price: '', suggestions: [] }]);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [companies, setCompanies] = useState([]);
//   const [selectedCompany, setSelectedCompany] = useState(null);
//   const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

//   const [clients, setClients] = useState([]);
//   const [selectedClient, setSelectedClient] = useState(null);
//   const [clientSearchTerm, setClientSearchTerm] = useState('');
//   const [debouncedClientSearchTerm, setDebouncedClientSearchTerm] = useState('');
//   const [clientSuggestions, setClientSuggestions] = useState([]);

//   // Debounce for client search term
//   useEffect(() => {
//     const handler = setTimeout(() => {
//       setDebouncedClientSearchTerm(clientSearchTerm);
//     }, 500);
//     return () => clearTimeout(handler);
//   }, [clientSearchTerm]);

//   // Fetch clients sequentially by name, nickname, or GST number
//   useEffect(() => {
//     const fetchClientData = async () => {
//       const token = localStorage.getItem('token');

//       if (debouncedClientSearchTerm) {
//         try {
//           // First, try to search by name
//           let response = await axios.get(`https://emkayindia.in/api/v1/client/search?name=${debouncedClientSearchTerm}`, {
//             headers: { 'Authorization': `Bearer ${token}` }
//           });

//           // If no clients found by name, search by nickname
//           if (response.data.length === 0) {
//             response = await axios.get(`https://emkayindia.in/api/v1/client/search?nickname=${debouncedClientSearchTerm}`, {
//               headers: { 'Authorization': `Bearer ${token}` }
//             });
//           }

//           // If no clients found by nickname, search by GST number
//           if (response.data.length === 0) {
//             response = await axios.get(`https://emkayindia.in/api/v1/client/search?gstno=${debouncedClientSearchTerm}`, {
//               headers: { 'Authorization': `Bearer ${token}` }
//             });
//           }

//           // Set the results from the final API call
//           setClientSuggestions(response.data);
//         } catch (error) {
//           console.error('Error fetching clients:', error);
//           setClientSuggestions([]); // Clear suggestions if error occurs
//         }
//       } else {
//         setClientSuggestions([]); // Clear suggestions if search term is empty
//       }
//     };

//     fetchClientData();
//   }, [debouncedClientSearchTerm]);
//   // Debounce for product search term
//   useEffect(() => {
//     const handler = setTimeout(() => {
//       setDebouncedSearchTerm(searchTerm);
//     }, 500);

//     return () => {
//       clearTimeout(handler);
//     };
//   }, [searchTerm]);

//   // Fetch products based on debounced search term
//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     if (selectedCompany && debouncedSearchTerm) {
//       axios.get(`https://emkayindia.in/api/v1/product/search`, {
//         params: {
//           companyId: selectedCompany.id,
//           searchTerm: debouncedSearchTerm,
//         },
//         headers: {
//           'Authorization': `Bearer ${token}`
//         }
//       })
//         .then(response => {
//           const newBillingProducts = billingProducts.map((product) =>
//             product.name === searchTerm ? { ...product, suggestions: response.data } : product
//           );
//           setBillingProducts(newBillingProducts);
//         })
//         .catch(error => console.error('Error fetching products:', error));
//     }
//   }, [selectedCompany, debouncedSearchTerm]);

//   // Fetch companies
//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     axios.get('https://emkayindia.in/api/v1/company/all', {
//       headers: { 'Authorization': `Bearer ${token}` }
//     })
//       .then(response => {
//         setCompanies(response.data);
//       })
//       .catch(error => console.error('Error fetching companies:', error));
//   }, []);

//   const handleClientChange = (client) => {
//     setSelectedClient(client);
//     setClientSearchTerm(''); // Clear the search term after selecting
//   };

//   const handleCompanyChange = (event) => {
//     const companyId = event.target.value;
//     const company = companies.find(c => c.id === companyId);
//     setSelectedCompany(company);
//   };

//   const handleChange = (index, field, value) => {
//     const newBillingProducts = billingProducts.map((product, i) =>
//       i === index ? { ...product, [field]: value, suggestions: [] } : product
//     );
//     setBillingProducts(newBillingProducts);

//     if (field === 'name') {
//       setSearchTerm(value);
//     }
//   };

//   const handleSelectProduct = (index, product) => {
//     const newBillingProducts = billingProducts.map((p, i) =>
//       i === index ? { ...p, name: product.name, id: product.id, quantity: '1', price: product.price, suggestions: [] } : p
//     );
//     setBillingProducts(newBillingProducts);
//     setSearchTerm('');
//   };
//   const handleAddRow = () => {
//     if (!selectedCompany) {
//       alert('Please select a company before adding products.');
//       return;
//     }

//     const lastProduct = billingProducts[billingProducts.length - 1];
//     if (lastProduct.name && lastProduct.quantity && lastProduct.price) {
//       setBillingProducts([...billingProducts, { name: '', quantity: '', price: '', suggestions: [] }]);
//     } else {
//       alert('Please fill in all fields of the current row before adding a new one.');
//     }
//   };
//   const handleRemoveRow = (index) => {
//     if (billingProducts.length > 1) {
//       const newBillingProducts = billingProducts.filter((_, i) => i !== index);
//       setBillingProducts(newBillingProducts);
//     }
//   };
//   const handlePrint = () => {
//     const printWindow = window.open('', '', 'height=600,width=800');
//     if (!billingProducts.length || !billingProducts[0].name) {
//       alert('No products added');
//       return;
//     }
//     if (!printWindow) {
//       alert('Failed to open print window');
//       return;
//     }

//     const content = `
//   <html>
//   <head>
//     <title>Print Bill</title>
//     <style>
//       body { font-family: Arial, sans-serif; margin: 20px; }
//       table { width: 100%; border-collapse: collapse; }
//       th, td { border: 1px solid #ddd; padding: 8px; }
//       th { background-color: #f2f2f2; }
//       .total { text-align: right; font-weight: bold; }
//     </style>
//   </head>
//   <body>
//     <h1>Bill</h1>
//     <table>
//       <thead>
//         <tr>
//           <th>Product Name</th>
//           <th>Quantity</th>
//           <th>Price</th>
//           <th>Total</th>
//         </tr>
//       </thead>
//       <tbody>
//         ${billingProducts.map(product => `
//           <tr>
//             <td>${product.name}</td>
//             <td>${product.quantity}</td>
//             <td>${parseFloat(product.price).toFixed(2)}</td>
//             <td>${(product.quantity * product.price).toFixed(2)}</td>
//           </tr>
//         `).join('')}
//         <tr>
//           <td colspan="3" class="total">Total</td>
//           <td class="total">${getTotal()}</td>
//         </tr>
//       </tbody>
//     </table>
//   </body>
//   </html>
// `;

//     printWindow.document.open();
//     printWindow.document.write(content);
//     printWindow.document.close();
//     printWindow.focus();
//     printWindow.print();
//   };
//   const getTotal = () => {
//     return billingProducts.reduce((acc, product) => {
//       const discountAmount = (product.price * (product.discount || 0)) / 100;
//       const priceAfterDiscount = product.price - discountAmount;
//       return acc + (priceAfterDiscount * product.quantity);
//     }, 0).toFixed(2);
//   };
//   const handleSubmit = async () => {
//     if (!selectedCompany || !selectedClient || !billingProducts.length) {
//       alert("Please fill out all fields before submitting.");
//       return;
//     }

//     const token = localStorage.getItem('token');

//     const quotationData = {
//       quotationNo: "abc123",  // You can generate or pass the quotation number dynamically
//       clientId: selectedClient.id,
//       companyId: selectedCompany.id,
//       items: billingProducts.map(product => ({
//         productId: product.id,  // Assuming product ID is available after selecting a product
//         quantity: parseFloat(product.quantity),
//         discount: parseFloat(product.discount || 0),  // Include the manually entered discount
//         price: product.price
//       }))
//     };

//     try {
//       const response = await axios.post('https://emkayindia.in/api/v1/quotation/create', quotationData, {
//         headers: {
//           'Authorization': `Bearer ${token}`
//         }
//       });

//       if (response.status === 200) {
//         console.log("Quatation Submitted");
//         // alert('Quotation submitted successfully!');
//         toast.success("Quatation Created");
//       }
//     } catch (error) {
//       console.error('Error submitting quotation:', error);
//       toast.error("Something went wrong");
//     }

//     toggleComponent('CreateQuatation');
//   };



//   return (
//     <div className="BillingComponent">
//       <div className="ClientSelector">
//         <h1>Select Client</h1>
//         <input
//           type="text"
//           placeholder="Search by client name, nickname, or GST no."
//           value={clientSearchTerm}
//           onChange={(e) => setClientSearchTerm(e.target.value)}
//         />
//         {clientSuggestions.length > 0 && (
//           <ul className="client-suggestions">
//             {clientSuggestions.map(client => (
//               <li key={client.id} onClick={() => handleClientChange(client)}>
//                 {client.name} - {client.gstNo}
//               </li>
//             ))}
//           </ul>
//         )}

//         {selectedClient && (
//           <div className="client-details">
//             <h2>{selectedClient.name}</h2>
//             <p><strong>GST No:</strong> {selectedClient.gstNo}</p>
//             <p><strong>Email:</strong> {selectedClient.email}</p>
//             <p><strong>Phone:</strong> {selectedClient.phone}</p>
//           </div>
//         )}
//       </div>

//       <div className="CompanySelector">
//         <h1>Select Company</h1>
//         <select onChange={handleCompanyChange} value={selectedCompany?.id || ''}>
//           <option value="" disabled>Select Company</option>
//           {companies.map(company => (
//             <option key={company.id} value={company.id}>{company.companyName}</option>
//           ))}
//         </select>
//       </div>
//       {selectedCompany && (
//         <div className="company-details">
//           <h2>{selectedCompany.companyName}</h2>
//           <p><strong>GST No:</strong> {selectedCompany.gstNo}</p>
//           <p><strong>Email:</strong> {selectedCompany.email}</p>
//           <p><strong>Phone:</strong> {selectedCompany.phone}</p>
//           <p><strong>Address:</strong> {selectedCompany.address}</p>
//           <img src={selectedCompany.logo} alt="Company Logo" style={{ maxWidth: '200px' }} />
//         </div>
//       )}

//       <h1>Billing</h1>
//       <div className="input-section">
//         {billingProducts.map((product, index) => (
//           <div key={index} className="product-row">
//             <input
//               type="text"
//               placeholder="Product Name"
//               value={product.name}
//               onChange={(e) => handleChange(index, 'name', e.target.value)}
//               disabled={!selectedCompany}
//             />
//             <input
//               type="text"
//               placeholder="Product id"
//               value={product.id}
//               onChange={(e) => handleChange(index, 'id', e.target.value)}
//               disabled={!selectedCompany}
//             // style={dis}
//             />
//             {product.suggestions.length > 0 && (
//               <ul className="suggestions">
//                 {product.suggestions.map((suggestion) => (
//                   <li
//                     key={suggestion.id}
//                     onClick={() => handleSelectProduct(index, suggestion)}
//                   >
//                     {suggestion.name} - ${suggestion.price}
//                   </li>
//                 ))}
//               </ul>
//             )}
//             <input
//               type="number"
//               placeholder="Quantity"
//               value={product.quantity}
//               onChange={(e) => handleChange(index, 'quantity', e.target.value)}
//               min="1"
//               disabled={!selectedCompany}
//             />
//             <input
//               type="number"
//               placeholder="discount"
//               value={product.discount}
//               onChange={(e) => handleChange(index, 'discount', e.target.value)}
//               min="0"
//               disabled={!selectedCompany}
//             />
//             <input
//               type="number"
//               placeholder="Price"
//               value={product.price}
//               onChange={(e) => handleChange(index, 'price', e.target.value)}
//               min="0"
//               disabled={!selectedCompany}
//             />
//             <button onClick={() => handleRemoveRow(index)}>Remove</button>
//             {index === billingProducts.length - 1 && (
//               <button onClick={handleAddRow}>Add Row</button>
//             )}
//           </div>
//         ))}
//       </div>
//       {billingProducts.length > 0 && billingProducts[0].name && (
//         <div className="bill">
//           <h2>Total: ${getTotal()}</h2>
//           <button onClick={handlePrint}>Print Bill</button>
//         </div>
//       )}
//       {billingProducts.length > 0 && billingProducts[0].name && (
//         <div>
//           <button onClick={handleSubmit}>Submit Quotation</button>
//         </div>
//       )}

//     </div>
//   );
// };

// export default CreateQuatation;
// import React, { useState, useEffect } from 'react';
// import "../css/CreateQuatation.css";
// import axios from 'axios';
// import { toast } from 'react-toastify';

// const CreateQuatation = ({ toggleComponent }) => {
//   const [billingProducts, setBillingProducts] = useState([{ name: '', quantity: '', price: '', suggestions: [] }]);
//   const [companies, setCompanies] = useState([]);
//   const [selectedCompany, setSelectedCompany] = useState(null);
//   const [clients, setClients] = useState([]);
//   const [selectedClient, setSelectedClient] = useState(null);
//   const [clientSearchTerm, setClientSearchTerm] = useState('');
//   const [clientSuggestions, setClientSuggestions] = useState([]);
//   const [searchTerm, setSearchTerm] = useState('');

//   // Fetch clients by name, nickname, or GST number
//   useEffect(() => {
//     const fetchClientData = async (searchTerm) => {
//       const token = localStorage.getItem('token');
//       try {
//         let response = await axios.get(`https://emkayindia.in/api/v1/client/search?name=${searchTerm}`, {
//           headers: { Authorization: `Bearer ${token}` }
//         });
//         setClientSuggestions(response.data);
//       } catch (error) {
//         console.error('Error fetching clients:', error);
//         setClientSuggestions([]);
//       }
//     };
//     if (clientSearchTerm) {
//       fetchClientData(clientSearchTerm);
//     }
//   }, [clientSearchTerm]);

//   // Fetch companies
//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     axios.get('https://emkayindia.in/api/v1/company/all', {
//       headers: { Authorization: `Bearer ${token}` }
//     })
//       .then(response => setCompanies(response.data))
//       .catch(error => console.error('Error fetching companies:', error));
//   }, []);

//   // Fetch products based on company and search term
//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     if (selectedCompany && searchTerm) {
//       axios.get(`https://emkayindia.in/api/v1/product/search`, {
//         params: { companyId: selectedCompany.id, searchTerm },
//         headers: { Authorization: `Bearer ${token}` }
//       })
//         .then(response => {
//           const newBillingProducts = billingProducts.map(product =>
//             product.name === searchTerm ? { ...product, suggestions: response.data } : product
//           );
//           setBillingProducts(newBillingProducts);
//         })
//         .catch(error => console.error('Error fetching products:', error));
//     }
//   }, [selectedCompany, searchTerm]);

//   const handleClientChange = (client) => {
//     setSelectedClient(client);
//     setClientSearchTerm('');
//   };

//   const handleCompanyChange = (event) => {
//     const companyId = event.target.value;
//     const company = companies.find(c => c.id === companyId);
//     setSelectedCompany(company);
//   };

//   const handleChange = (index, field, value) => {
//     const newBillingProducts = billingProducts.map((product, i) =>
//       i === index ? { ...product, [field]: value, suggestions: [] } : product
//     );
//     setBillingProducts(newBillingProducts);

//     if (field === 'name') {
//       setSearchTerm(value);
//     }
//   };

//   const handleSelectProduct = (index, product) => {
//     const newBillingProducts = billingProducts.map((p, i) =>
//       i === index ? { ...p, name: product.name, id: product.id, quantity: '1', price: product.price, suggestions: [] } : p
//     );
//     setBillingProducts(newBillingProducts);
//     setSearchTerm('');
//   };

//   const handleAddRow = () => {
//     setBillingProducts([...billingProducts, { name: '', quantity: '', price: '', suggestions: [] }]);
//   };

//   const handleSubmit = async () => {
//     if (!selectedCompany || !selectedClient || !billingProducts.length) {
//       alert('Please fill out all fields before submitting.');
//       return;
//     }

//     const token = localStorage.getItem('token');
//     const quotationData = {
//       quotationNo: "abc123",
//       clientId: selectedClient.id,
//       companyId: selectedCompany.id,
//       items: billingProducts.map(product => ({
//         productId: product.id,
//         quantity: parseFloat(product.quantity),
//         discount: parseFloat(product.discount || 0),
//         price: product.price
//       }))
//     };

//     try {
//       const response = await axios.post('https://emkayindia.in/api/v1/quotation/create', quotationData, {
//         headers: { Authorization: `Bearer ${token}` }
//       });
//       if (response.status === 200) {
//         toast.success("Quotation created successfully!");
//       }
//     } catch (error) {
//       toast.error("Failed to create quotation.");
//       console.error('Error submitting quotation:', error);
//     }
//     toggleComponent('CreateQuatation');
//   };

//   return (
//     <div className="CreateQuatation">
//       <div className="section">
//         <label>Client</label>
//         <input
//           type="text"
//           placeholder="Search Client by name, GST, or nickname"
//           value={clientSearchTerm}
//           onChange={(e) => setClientSearchTerm(e.target.value)}
//         />
//         {clientSuggestions.length > 0 && (
//           <ul className="dropdown">
//             {clientSuggestions.map(client => (
//               <li key={client.id} onClick={() => handleClientChange(client)}>
//                 {client.name} - {client.gstNo}
//               </li>
//             ))}
//           </ul>
//         )}
//         {selectedClient && (
//           <div className="selected-info">
//             <p>Client: {selectedClient.name}</p>
//             <p>GST: {selectedClient.gstNo}</p>
//           </div>
//         )}
//       </div>

//       <div className="section">
//         <label>Company</label>
//         <select onChange={handleCompanyChange}>
//           <option value="">Select Company</option>
//           {companies.map(company => (
//             <option key={company.id} value={company.id}>{company.companyName}</option>
//           ))}
//         </select>
//         {selectedCompany && (
//           <div className="selected-info">
//             <p>Company: {selectedCompany.companyName}</p>
//             <p>GST: {selectedCompany.gstNo}</p>
//           </div>
//         )}
//       </div>

//       <div className="section">
//         <label>Products</label>
//         {billingProducts.map((product, index) => (
//           <div key={index} className="product-row">
//             <input
//               type="text"
//               placeholder="Product Name"
//               value={product.name}
//               onChange={(e) => handleChange(index, 'name', e.target.value)}
//             />
//             {product.suggestions.length > 0 && (
//               <ul className="dropdown">
//                 {product.suggestions.map(suggestion => (
//                   <li key={suggestion.id} onClick={() => handleSelectProduct(index, suggestion)}>
//                     {suggestion.name} - ${suggestion.price}
//                   </li>
//                 ))}
//               </ul>
//             )}
//             <input
//               type="number"
//               placeholder="Quantity"
//               value={product.quantity}
//               onChange={(e) => handleChange(index, 'quantity', e.target.value)}
//             />
//             <input
//               type="number"
//               placeholder="Discount"
//               value={product.discount}
//               onChange={(e) => handleChange(index, 'discount', e.target.value)}
//             />
//             <input
//               type="number"
//               placeholder="Price"
//               value={product.price}
//               onChange={(e) => handleChange(index, 'price', e.target.value)}
//             />
//             {index === billingProducts.length - 1 && (
//               <button onClick={handleAddRow}>Add Product</button>
//             )}
//           </div>
//         ))}
//       </div>

//       <button onClick={handleSubmit} className="submit-btn">Submit Quotation</button>
//     </div>
//   );
// };

// export default CreateQuatation;



// import React, { useState, useEffect } from 'react';
// import "./css/CreateQuatation.css";
// import axios from 'axios';
// import { toast } from 'react-toastify';

// const CreateQuotation = ({ toggleComponent }) => {
//   const initialProductRow = { name: '', quantity: '', price: '', discount: '', suggestions: [] };
//   const [billingProducts, setBillingProducts] = useState(Array(10).fill(initialProductRow));
//   const [companies, setCompanies] = useState([]);
//   const [selectedCompany, setSelectedCompany] = useState(null);
//   const [clients, setClients] = useState([]);
//   const [selectedClient, setSelectedClient] = useState(null);
//   const [clientSearchTerm, setClientSearchTerm] = useState('');
//   const [clientSuggestions, setClientSuggestions] = useState([]);
//   const [searchTerm, setSearchTerm] = useState('');

//   // Fetch clients by name, nickname, or GST number
//   useEffect(() => {
//     const fetchClientData = async (searchTerm) => {
//       const token = localStorage.getItem('token');
//       try {
//         let response = await axios.get(`https://emkayindia.in/api/v1/client/search?name=${searchTerm}`, {
//           headers: { Authorization: `Bearer ${token}` }
//         });
//         setClientSuggestions(response.data);
//       } catch (error) {
//         console.error('Error fetching clients:', error);
//         setClientSuggestions([]);
//       }
//     };
//     if (clientSearchTerm) {
//       fetchClientData(clientSearchTerm);
//     }
//   }, [clientSearchTerm]);

//   // Fetch companies
//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     axios.get('https://emkayindia.in/api/v1/company/all', {
//       headers: { Authorization: `Bearer ${token}` }
//     })
//       .then(response => setCompanies(response.data))
//       .catch(error => console.error('Error fetching companies:', error));
//   }, []);

//   // Fetch products based on company and search term
//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     if (selectedCompany && searchTerm) {
//       axios.get('https://emkayindia.in/api/v1/product/search', {
//         params: { companyId: selectedCompany.id, searchTerm },
//         headers: { Authorization: `Bearer ${token}` }
//       })
//         .then(response => {
//           const newBillingProducts = billingProducts.map(product =>
//             product.name === searchTerm ? { ...product, suggestions: response.data } : product
//           );
//           setBillingProducts(newBillingProducts);
//         })
//         .catch(error => console.error('Error fetching products:', error));
//     }
//   }, [selectedCompany, searchTerm]);

//   const handleClientChange = (client) => {
//     setSelectedClient(client);
//     setClientSearchTerm('');
//   };

//   const handleCompanyChange = (event) => {
//     const companyId = event.target.value;
//     const company = companies.find(c => c.id === companyId);
//     setSelectedCompany(company);
//   };

//   const handleChange = (index, field, value) => {
//     const newBillingProducts = billingProducts.map((product, i) =>
//       i === index ? { ...product, [field]: value, suggestions: [] } : product
//     );
//     setBillingProducts(newBillingProducts);

//     if (field === 'name') {
//       setSearchTerm(value);
//     }
//   };

//   const handleSelectProduct = (index, product) => {
//     const newBillingProducts = billingProducts.map((p, i) =>
//       i === index ? { ...p, name: product.name, id: product.id, quantity: '1', price: product.price, suggestions: [] } : p
//     );
//     setBillingProducts(newBillingProducts);
//     setSearchTerm('');
//   };

//   const handleAddRow = () => {
//     // if (billingProducts.length < 15) {
//       setBillingProducts([...billingProducts, initialProductRow]);
//     // }
//   };

//   const handleRemoveRow = (index) => {
//     const newBillingProducts = billingProducts.filter((_, i) => i !== index);
//     setBillingProducts(newBillingProducts);
//   };

//   const handleSubmit = async () => {
//     const validProducts = billingProducts.filter(product => product.name && product.quantity);
//     if (!selectedCompany || !selectedClient || validProducts.length === 0) {
//       alert('Please fill out all fields before submitting.');
//       return;
//     }

//     const token = localStorage.getItem('token');
//     const quotationData = {
//       quotationNo: "abc123",
//       clientId: selectedClient.id,
//       companyId: selectedCompany.id,
//       items: validProducts.map(product => ({
//         productId: product.id,
//         quantity: parseFloat(product.quantity),
//         discount: parseFloat(product.discount || 0),
//         price: product.price
//       }))
//     };

//     try {
//       const response = await axios.post('https://emkayindia.in/api/v1/quotation/create', quotationData, {
//         headers: { Authorization: `Bearer ${token}` }
//       });
//       if (response.status === 200) {
//         toast.success("Quotation created successfully!");
//       }
//     } catch (error) {
//       toast.error("Failed to create quotation.");
//       console.error('Error submitting quotation:', error);
//     }
//     toggleComponent('CreateQuotation');
//   };

//   return (
//     <div className="CreateQuotation-component">
//       <div className="section">
//       <div className="row">
//         <div className="company-section">
//           <label htmlFor="company">Select Company:</label>
//           <select id="company" onChange={handleCompanyChange}>
//             <option value="">Select a company</option>
//             {companies.map(company => (
//               <option key={company.id} value={company.id}>{company.companyName}</option>
//             ))}
//           </select>
//           {selectedCompany && (
//             <div className="company-details">
//               <p><strong>Name:</strong> {selectedCompany.companyName}</p>
//               <p><strong>GST No:</strong> {selectedCompany.gstNo}</p>
//               <p><strong>Email:</strong> {selectedCompany.email}</p>
//               <p><strong>Phone:</strong> {selectedCompany.phone}</p>
//               <p><strong>Address:</strong> {selectedCompany.address}</p>
//             </div>
//           )}
//         </div>

//         <div className="client-section">
//           <label htmlFor="client">Select Client:</label>
//           <input
//             type="text"
//             value={clientSearchTerm}
//             onChange={(e) => setClientSearchTerm(e.target.value)}
//             placeholder="Type client name, nickname, or GST number"
//           />
//           <ul>
//             {clientSuggestions.map(client => (
//               <li key={client.id} onClick={() => handleClientChange(client)}>
//                 {client.name} - {client.nickname} - {client.gstNo}
//               </li>
//             ))}
//           </ul>
//           {selectedClient && (
//             <div className="client-details">
//               <p><strong>Name:</strong> {selectedClient.name}</p>
//               <p><strong>Nickname:</strong> {selectedClient.nickname}</p>
//               <p><strong>Email:</strong> {selectedClient.email}</p>
//               <p><strong>Phone:</strong> {selectedClient.phone}</p>
//               <p><strong>Address:</strong> {selectedClient.address}</p>
//               <p><strong>Contact Person:</strong> {selectedClient.contactPerson}</p>
//             </div>
//           )}
//         </div>
//       </div>
//       </div>

//       <div className="section">
//         <label>Products</label>
//         {billingProducts.map((product, index) => (
//           <div key={index} className="product-row">
//             <input
//               type="text"
//               placeholder="SR. No"
//               value={index+1}
//               onChange={(e) => handleChange(index, 'serialNo', e.target.value)}
//             />
//             <input
//               type="text"
//               placeholder="Product Id"
//               value={product.id}
//               onChange={(e) => handleChange(index, 'id', e.target.value)}
//             />
//             <input
//               type="text"
//               placeholder="Product Name"
//               value={product.name}
//               onChange={(e) => handleChange(index, 'name', e.target.value)}
//             />
//             <input
//               type="text"
//               placeholder="hsn"
//               value={product.hsn}
//               onChange={(e) => handleChange(index, 'hsn', e.target.value)}
//             />
//             <input
//               type="number"
//               placeholder="Quantity"
//               value={product.quantity}
//               onChange={(e) => handleChange(index, 'quantity', e.target.value)}
//             />
//             <input
//               type="number"
//               placeholder="Discount"
//               value={product.discount}
//               onChange={(e) => handleChange(index, 'discount', e.target.value)}
//             />
//             <input
//               type="number"
//               placeholder="GST"
//               value={product.gstNo}
//               onChange={(e) => handleChange(index, 'gst', e.target.value)}
//             />
//             <input
//               type="number"
//               placeholder="Price"
//               value={product.price}
//               onChange={(e) => handleChange(index, 'price', e.target.value)}
//             />

//             <button onClick={() => handleRemoveRow(index)} className="remove-row-btn">Remove</button>
//             {product.suggestions.length > 0 && (
//               <ul className="product-dropdown">
//                 {product.suggestions.map(suggestion => (
//                   <li key={suggestion.id} onClick={() => handleSelectProduct(index, suggestion)}>
//                     {suggestion.name} - ${suggestion.price} -HSN-{suggestion.hsn}
//                   </li>
//                 ))}
//               </ul>
//             )}
//             {index === billingProducts.length - 1 && (
//               <button onClick={handleAddRow} className="add-row-btn">Add Product</button>
//             )}

//           </div>

//         ))}
//       </div>

//       <button onClick={handleSubmit} className="submit-btn">Submit Quotation</button>
//     </div>
//   );
// };

// export default CreateQuotation;













// import React, { useState, useEffect } from 'react';
// import "./css/CreateQuatation.css";
// import axios from 'axios';
// import { toast } from 'react-toastify';

// const CreateQuotation = ({ toggleComponent }) => {
//   const initialProductRow = { serialNo: '', id: '', name: '', hsn: '', quantity: '', price: '', discount: 0, gst: 0, total: 0, suggestions: [] };
//   // const [billingProducts, setBillingProducts] = useState([initialProductRow]);
//   const [companies, setCompanies] = useState([]);
//   const [selectedCompany, setSelectedCompany] = useState(null);
//   const [clients, setClients] = useState([]);
//   const [selectedClient, setSelectedClient] = useState(null);
//   const [clientSearchTerm, setClientSearchTerm] = useState('');
//   const [clientSuggestions, setClientSuggestions] = useState([]);
//   const [searchTerm, setSearchTerm] = useState('');
//   const initialRows = Array.from({ length: 5 }, (_, index) => ({ ...initialProductRow, serialNo: index + 1 }));
// const [billingProducts, setBillingProducts] = useState(initialRows);
// const [termsAndConditions,setTermsAndConditions] = useState('');

//   useEffect(() => {
//     const fetchClientData = async (searchTerm) => {
//       const token = localStorage.getItem('token');
//       try {
//         let response = await axios.get(`https://emkayindia.in/api/v1/client/search?name=${searchTerm}`, {
//           headers: { Authorization: `Bearer ${token}` }
//         });
//         setClientSuggestions(response.data);
//       } catch (error) {
//         console.error('Error fetching clients:', error);
//         setClientSuggestions([]);
//       }
//     };
//     if (clientSearchTerm) {
//       fetchClientData(clientSearchTerm);
//     }
//   }, [clientSearchTerm]);

//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     axios.get('https://emkayindia.in/api/v1/company/all', {
//       headers: { Authorization: `Bearer ${token}` }
//     })
//       .then(response => setCompanies(response.data))
//       .catch(error => console.error('Error fetching companies:', error));
//   }, []);

//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     if (selectedCompany && searchTerm) {
//       axios.get('https://emkayindia.in/api/v1/product/search', {
//         params: { companyId: selectedCompany.id, searchTerm },
//         headers: { Authorization: `Bearer ${token}` }
//       })
//         .then(response => {
//           const newBillingProducts = billingProducts.map(product =>
//             product.name === searchTerm ? { ...product, suggestions: response.data } : product
//           );
//           setBillingProducts(newBillingProducts);
//         })
//         .catch(error => console.error('Error fetching products:', error));
//     }
//   }, [selectedCompany, searchTerm]);

//   const handleClientChange = (client) => {
//     setSelectedClient(client);
//     setClientSearchTerm('');
//   };

//   const handleCompanyChange = (event) => {
//     const companyId = event.target.value;
//     const company = companies.find(c => c.id === companyId);
//     setTermsAndConditions(company.terms.termsAndConditions);

//     setSelectedCompany(company);
//   };

//   const handleChange = (index, field, value) => {
//     if(!selectedCompany ){
//       alert("Select Company before adding Products")
//       return;
//     }
//     else if(!selectedClient){
//       alert("Select Client before adding Products")
//       return;
//     }
//     const newBillingProducts = billingProducts.map((product, i) => {
//       if (i === index) {
//         const updatedProduct = { ...product, [field]: value, suggestions: [] };

//         // Recalculate total for the row
//         if (field === 'quantity' || field === 'price' || field === 'discount' || field === 'gst') {
//           const { quantity, price, discount, gst } = updatedProduct;
//           const subtotal = quantity * price;
//           const discountAmount = (subtotal * discount) / 100;
//           const gstAmount = (subtotal - discountAmount) * (gst / 100);
//           updatedProduct.total = subtotal - discountAmount + gstAmount;
//         }

//         return updatedProduct;
//       }
//       return product;
//     });
//     setBillingProducts(newBillingProducts);

//     if (field === 'name') {
//       setSearchTerm(value);
//     }
//   };

//   const handleSelectProduct = (index, product) => {
//     const newBillingProducts = billingProducts.map((p, i) =>
//       i === index ? { ...p, name: product.name, id: product.id, quantity: 1, price: product.price,gst:product.gst, hsn: product.hsn, suggestions: [] } : p
//     );
//     console.log(newBillingProducts)
//     setBillingProducts(newBillingProducts);
//     setSearchTerm('');
//   };

//   const handleAddRow = () => {
//     setBillingProducts([...billingProducts, initialProductRow]);
//   };

//   const handleRemoveRow = (index) => {
//     const newBillingProducts = billingProducts.filter((_, i) => i !== index);
//     setBillingProducts(newBillingProducts);
//   };

//   const handleSubmit = async () => {
//     const validProducts = billingProducts.filter(product => product.name && product.quantity);
//     if (!selectedCompany || !selectedClient || validProducts.length === 0) {
//       alert('Please fill out all fields before submitting.');
//       return;
//     }

//     const token = localStorage.getItem('token');
//     const quotationData = {
//       clientId: selectedClient.id,
//       companyId: selectedCompany.id,
//       items: validProducts.map(product => ({
//         productId: product.id,
//         quantity: parseFloat(product.quantity),
//         discount: parseFloat(product.discount || 0),
//         price: product.price
//       }
//     )),
//     terms:selectedCompany.terms,
//     banner:selectedCompany.banner
//     };

//     try {
//       const response = await axios.post('https://emkayindia.in/api/v1/quotation/create', quotationData, {
//         headers: { Authorization: `Bearer ${token}` }
//       });
//       if (response.status === 200) {
//         toast.success("Quotation created successfully!");
//       }
//     } catch (error) {
//       toast.error("Failed to create quotation.");
//       console.error('Error submitting quotation:', error);
//     }
//     toggleComponent('CreateQuotation');
//   };

//   return (
//     <div className="CreateQuotation-component">
//       <h2>Create Quatation</h2>
//       <div className="section">
//         <div className="row">
//           <div className="company-section">
//             <label htmlFor="company">Select Company:</label>
//             <select id="company" onChange={handleCompanyChange}>
//               <option value="">Select a company</option>
//               {companies.map(company => (
//                 <option key={company.id} value={company.id}>{company.companyName}</option>
//               ))}
//             </select>
//             {selectedCompany && (
//               <div className="company-details">
//                 <p><strong>Name:</strong> {selectedCompany.companyName}</p>
//                 <p><strong>GST No:</strong> {selectedCompany.gstNo}</p>
//                 <p><strong>Email:</strong> {selectedCompany.email}</p>
//                 <p><strong>Phone:</strong> {selectedCompany.phone}</p>
//                 <p><strong>Address:</strong> {selectedCompany.address}</p>
//               </div>
//             )}
//           </div>

//           <div className="client-section">
//             <label htmlFor="client">Select Client:</label>
//             <input
//               type="text"
//               value={clientSearchTerm}
//               onChange={(e) => setClientSearchTerm(e.target.value)}
//               placeholder="Type client name, nickname, or GST number"
//             />
//             <ul>
//               {!selectedClient && clientSuggestions.map(client => (
//                 <li key={client.id} onClick={() => handleClientChange(client)}>
//                   {client.name} - {client.nickname} - {client.gstNo}
//                 </li>
//               ))}
//             </ul>
//             {selectedClient && (
//               <div className="client-details">
//                 <p><strong>Name:</strong> {selectedClient.name}</p>
//                 <p><strong>Nickname:</strong> {selectedClient.nickname}</p>
//                 <p><strong>Email:</strong> {selectedClient.email}</p>
//                 <p><strong>GST No.:</strong> {selectedClient.gstNo}</p>
//                 <p><strong>Phone:</strong> {selectedClient.phone}</p>
//                 <p><strong>Address:</strong> {selectedClient.address}</p>
//                 <p><strong>Contact Person:</strong> {selectedClient.contactPerson}</p>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>

//       <div className="section">
//         <label>Products</label>
//         <div className="product-header">
//           <span>SR. No</span>
//           <span>Product Name</span>
//           <span>Product Id</span>
//           <span>HSN</span>
//           <span>Quantity</span>
//           <span>Discount (%)</span>
//           <span>GST (%)</span>
//           <span>Price</span>
//           <span>Total</span>
//         </div>
//         {billingProducts.map((product, index) => (
//           <div key={index} className="product-row">
//             <input
//               type="text"
//               placeholder="SR. No"
//               value={index + 1}
//               readOnly
//               className='srNoInput'
//             />
//             {/* <p className='srNoInput'>{index+1}</p> */}
//            <div className='productName-input'>
//            <input
//               type="text"
//               placeholder="Product Name"
//               value={product.name}

//               onChange={(e) => handleChange(index, 'name', e.target.value)}
//             />
//             {product.suggestions.length > 0 && (
//               <ul className='suggestions-dropdown'>
//                 {product.suggestions.map(suggestion => (
//                   <li key={suggestion.id} onClick={() => handleSelectProduct(index, suggestion)}>
//                     {suggestion.name + ' - ' + suggestion.brand  + ' - ' + suggestion.variant  + ' - '+ suggestion.price  + ' - '+ suggestion.category}
//                   </li>
//                 ))}
//               </ul>
//             )}
//            </div>

//              <input
//               type="text"
//               placeholder="Product Id"
//               value={product.id}
//               readOnly
//             />
//             <input
//               type="text"
//               placeholder="HSN"
//               value={product.hsn}
//               readOnly
//             />
//             <input
//               type="number"
//               placeholder="Quantity"
//               value={product.quantity}
//               onChange={(e) => handleChange(index, 'quantity', parseFloat(e.target.value))}
//             />
//             <input
//               type="number"
//               placeholder="Discount"
//               value={product.discount}
//               onChange={(e) => handleChange(index, 'discount', parseFloat(e.target.value))}
//             />
//             <input
//               type="number"
//               placeholder="GST"
//               value={product.gst}
//               onChange={(e) => handleChange(index, 'gst', parseFloat(e.target.value))}
//             />
//             <input
//               type="number"
//               placeholder="Price"
//               value={product.price}
//               onChange={(e) => handleChange(index, 'price', parseFloat(e.target.value))}
//             />
//             <input
//               type="number"
//               placeholder="Total"
//               value={product.price  - product.price*product.discount/100 }
//               readOnly
//             />
//             <button onClick={() => handleRemoveRow(index)} className='remove-btn'>-</button>

//           </div>
//         ))}
//         <button onClick={handleAddRow}>Add Product</button>
//       </div>
//       <div>
//         <h3>Terms & Conditions</h3>
//         <input 
//         type='text'
//         value={termsAndConditions}
//         onChange={(e) => setTermsAndConditions(e.value)}/>

//       </div>

//       <button onClick={handleSubmit} className='submit-btn'>Submit</button>
//     </div>
//   );
// };

// export default CreateQuotation;
import React, { useState, useEffect } from 'react';
import "./css/CreateQuatation.css";
import axios from 'axios';
import { toast } from 'react-toastify';


const CreateQuotation = ({ toggleComponent,selectedQuotationId,selectedCompany}) => {
  const initialProductRow = { serialNo: '', productId: '', name: '', hsn: '', brand: '', variant: '', quantity: '', price: '', discount: '', gst: '', total: '', suggestions: [] };
  const [companies, setCompanies] = useState([]);
  // const [selectedCompany, setSelectedCompany] = useState(null);
  const [clients, setClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const initialRows = Array.from({ length: 5 }, (_, index) => ({ ...initialProductRow, serialNo: index + 1 }));
  const [billingProducts, setBillingProducts] = useState(initialRows);
  const [terms, setTerms] = useState([]);

  const [quotationData, setQuotationData] = useState({
    clientId: '',
    companyId: '',
    items: [],
    terms: '',
    banner: ''
  });
  const [clientSearchTerm, setClientSearchTerm] = useState('');
  const [clientSuggestions, setClientSuggestions] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [billType, setBillType] = useState('PI');



  // Handle change event
  const handleBillTypeChange = (e) => {
    setBillType(e.target.value);
    console.log(billType);
  };

  useEffect(() => {
    const fetchClientData = async (searchTerm) => {
      const token = localStorage.getItem('token');
      try {
        let response = await axios.get(`https://emkayindia.in/api/v1/client/search?name=${searchTerm}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setClientSuggestions(response.data);
      } catch (error) {
        console.error('Error fetching clients:', error);
        setClientSuggestions([]);
      }
    };
    if (clientSearchTerm) {
      fetchClientData(clientSearchTerm);
    }
  }, [clientSearchTerm, selectedClient]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get('https://emkayindia.in/api/v1/company/all', {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(response => setCompanies(response.data))
      .catch(error => console.error('Error fetching companies:', error));
    if (selectedQuotationId) {
      console.log("Inside create quatation")
      console.log(selectedQuotationId);
      // Prefill the required fields
      setSelectedClient(selectedQuotationId.client);  // Set client details
      // setSelectedCompany(selectedQuotationId.company); // Set company details
      // setQuotationDate(new Date(RecievedQuatationData.date).toISOString().substring(0, 10)); // Format date
      setTerms(selectedQuotationId.terms.termsAndConditions); // Assuming a single term for now
      if(selectedQuotationId.items){
        setBillingProducts(selectedQuotationId.items); } // Prefill items array
    }
    // console.log(billingProducts);
  }, []);



  useEffect(() => {
    const token = localStorage.getItem('token');
    if (selectedCompany && searchTerm) {
      axios.get(`http://localhost:3000/api/v1/product/name?companyId?`, {
        params: { companyId: selectedCompany.id, searchTerm },
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(response => {
          const newBillingProducts = billingProducts.map(product =>
            product.name === searchTerm ? { ...product, suggestions: response.data } : product
          );
          setBillingProducts(newBillingProducts);
        })
        .catch(error => console.error('Error fetching products:', error));
    }
  }, [selectedCompany, searchTerm]);

  const handleClientChange = (client) => {
    setSelectedClient(client);
    setClientSearchTerm(`${client.name} - ${client.nickname} - ${client.gstNo}`); // Show client name in input
    setClientSuggestions([]); // Hide suggestions after selecting a client
  };

  const handleCompanyChange = (event) => {
    const companyId = event.target.value;
    const company = companies.find(c => c.id === companyId);
    setTerms(company.terms.termsAndConditions);
    // setSelectedCompany(company);
    console.log(terms)
  };
  const calculateRowTotal = ({ quantity, price, discount, gst }) => {
    const subtotal = quantity * price;
    const discountAmount = (subtotal * discount) / 100;
    const gstAmount = (subtotal - discountAmount) * (gst / 100);
    return subtotal - discountAmount + gstAmount;
  };

  const handleChange = (index, field, value) => {
    if (!selectedCompany) {
      alert("Select Company before adding Products");
      return;
    } else if (!selectedClient) {
      alert("Select Client before adding Products");
      return;
    }
    const newBillingProducts = billingProducts.map((product, i) => {
      if (i === index) {
        const updatedProduct = { ...product, [field]: value, suggestions: [] };

        if (field === 'quantity' || field === 'price' || field === 'discount' || field === 'gst') {
          const { quantity, price, discount, gst } = updatedProduct;
          const subtotal = quantity * price;
          const discountAmount = (subtotal * discount) / 100;
          const gstAmount = (subtotal - discountAmount) * (gst / 100);
          updatedProduct.total = calculateRowTotal(updatedProduct);
        }

        return updatedProduct;
      }
      return product;
    });
    setBillingProducts(newBillingProducts);

    if (field === 'name') {
      setSearchTerm(value);
    }
  };

  const handleSelectProduct = (index, product) => {
    const newBillingProducts = billingProducts.map((p, i) =>
      i === index ? { ...p, name: product.name, id: product.id, brand: product.brand, variant: product.variant, quantity: 1, price: product.price, gst: product.gst, hsn: product.hsn, total: product.price, suggestions: [] } : p
    );
    setBillingProducts(newBillingProducts);
    setSearchTerm('');
  };

  const handleAddRow = () => {
    setBillingProducts([...billingProducts, initialProductRow]);
  };

  const handleRemoveRow = (index) => {
    const newBillingProducts = billingProducts.filter((_, i) => i !== index);
    setBillingProducts(newBillingProducts);
  };
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  //   documentTitle: 'Quotation',
  // });
  const handleSubmit = async () => {
    const validProducts = billingProducts.filter(product => product.name && product.quantity);
    if (!selectedCompany || !selectedClient || validProducts.length === 0) {
      alert('Please fill out all fields before submitting.');
      return;
    }

    const token = localStorage.getItem('token');
    const termsAndConditions = {
      termsAndConditions: terms
    };
    const newQuotationData = {
      clientId: selectedClient.id,
      companyId: selectedCompany.id,
      items: validProducts.map(product => ({
        productId: product.id,
        serialNo:parseFloat(product.serialNo),
        name:product.name,
        hsn:product.hsn,
        brand:product.brand,
        variant:product.variant,
        gst:String(product.gst),
        total:String(product.total),
        quantity: parseFloat(product.quantity),
        discount: parseFloat(product.discount || 0),
        price: product.price,
        suggestions:[]
      })),
      terms: termsAndConditions,
      banner: selectedCompany.banner
    };
    setQuotationData(newQuotationData);

    // console.log(quotationData);
    try {
      const response = await axios.post('https://emkayindia.in/api/v1/quotation/create', newQuotationData, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.status === 200) {
        toast.success("Quotation created successfully!");
        toggleComponent('CreateQuotation');
        // console.log(newQuotationData);

      }
      const createHtmlString = () => {
        const itemsHtml = newQuotationData.items
          .map(
            (item, index) => `
            <tr>
              <td>${index + 1}</td>
              <td>${item.productId}</td>
              <td>${item.quantity}</td>
              <td>${item.discount}</td>
              <td>${item.price.toFixed(2)}</td>
            </tr>`
          )
          .join('');

        return `
          
<html>
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Quotation</title>
    <style>
        /* A4 size setup */
        @page {
            size: A4;
            margin: 10mm;
        }

        body {
            font-family: Arial, sans-serif;
            width: 210mm;
            min-height: 297mm;
            margin: 0 auto;
            padding: 10px;
            box-sizing: border-box;
            font-size: 10px; /* Reduced font size for bill format */
            position: relative;
        }

        .quotation-header {
            width: 100%;
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;
        }

        .company-logo {
            display: flex;
            flex-direction: column; /* Stack logo and details vertically */
            align-items: flex-start; /* Align items to the left */
        }

        .company-logo img {
            width: 80px; /* Smaller logo */
            margin-bottom: 5px; /* Space between logo and company details */
        }

        .company-details {
            font-size: 10px; /* Adjusted size for company details */
        }

        .quotation-title {
            text-align: right;
            font-size: 16px; /* Reduced size for title */
            font-weight: bold;
            margin: 10px 0;
        }

        .quotation-details {
            text-align: center;
            margin-top: 5px;
            font-size: 10px; /* Adjusted size for details */
        }

        .bill-to, .ship-to {
            width: 20%;
            padding: 5px;
            box-sizing: border-box;
            margin-top: 10px;
            display: inline-block; /* Allow side by side */
        }

        /* Table Styling */
        table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 10px;
            font-size: 9px; /* Reduced font size for table */
        }

        table, th, td {
            border: 1px solid black;
        }

        th, td {
            padding: 4px; /* Reduced padding for compactness */
            text-align: center;
        }

        th {
            background-color: #f2f2f2;
        }

        .summary {
            text-align: right;
            padding: 5px;
            margin-top: 5px;
        }

        .summary p {
            margin: 2px 0;
        }

        /* Footer Section */
        .footer {
            width: 100%;
            position: absolute;
            bottom: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            padding-top: 10px;
            border-top: 1px solid #ddd;
            font-size: 9px; /* Reduced font size for footer */
            margin-bottom: 15px;
        }

        .terms-conditions {
            width: 45%;
        }

        .authorized-signature {
            width: 45%;
            text-align: right;
        }

        .first-line, .second-line {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .billing-details {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .footer-1 {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        /* Styling for when printing */
        @media print {
            .page-break {
                page-break-before: always;
            }
        }
    </style>
</head>
<body>

    <!-- Header Section -->
    <div class="quotation-header">
        <div class="first-line">
            <div class="company-logo">
                <img src=${selectedCompany.logo} alt="Company Logo">
            </div>
    
            <div class="quotation-title">
                Quotation
            </div>
        </div>
        <div class="second-line">
            <div class="company-details">
                <p><strong>${selectedCompany.companyName}</strong></p>
                <p><strong>GST No:</strong> ${selectedCompany.gstNo}</p>
                <p><strong>Email:</strong> ${selectedCompany.email}</p>
                <p><strong>Phone:</strong> ${selectedCompany.phone}</p>
                <p><strong>Address:</strong> ${selectedCompany.address}</p>
            </div>
            <div class="quotation-details">
                <p><strong>Quotation No:</strong> ${response?.data?.quotation.id}</p>
                <p><strong>Quotation Date:</strong>${response?.data?.quotation.createdAt}</p>
                <p><strong>Contact Person:</strong>${selectedClient.contactPerson}</p>
                <p><strong>Phone No:</strong> ${selectedClient.contactPhone}</p>
            </div>
        </div>
        
    </div>

    <!-- Quotation Details Below Title -->
    
    <hr/>

    <div class="billing-details">
        <div class="bill-to">
                <p><strong>Name</strong>${selectedClient.name}</p>
                <p><strong>GST No:</strong> ${selectedClient.gstNo}</p>
                <p><strong>Email:</strong> ${selectedClient.email}</p>
                <p><strong>Phone:</strong> ${selectedClient.phone}</p>
                <p><strong>Address:</strong> ${selectedClient.address}</p>
        </div>

        <div class="ship-to">
            <p><strong>Ship To</strong></p>
                <p><strong>Name</strong>${selectedClient.name}</p>
                <p><strong>GST No:</strong> ${selectedClient.gstNo}</p>
                <p><strong>Email:</strong> ${selectedClient.email}</p>
                <p><strong>Phone:</strong> ${selectedClient.phone}</p>
                <p><strong>Address:</strong> ${selectedClient.address}</p>
        </div>
    </div>

    <!-- Quotation Table -->
    <table>
        <thead>
            <tr>
                <th>S. No</th>
                <th>Description</th>
                <th>HSN</th>
                <th>Qty</th>
                <th>Rates/Unit (INR)</th>
                <th>Disc %</th>
                <th>Net Rate (INR)</th>
                <th colspan="2">CGST 
                    <br>%  Amount 
                </th>
                <th colspan="2">SGST
                    <br>%  Amount 
                </th>
                <th>Amount (INR)</th>
            </tr>
        </thead>
        <tbody id="product-rows">
        ${billingProducts.map(product => (product.name ? `
                     <tr>
                      <td>${product.serialNo}</td>
                       <td>${product.name}</td>
                       <td>${product.hsn}</td>
                       <td>${product.quantity}</td>
                       <td>${parseFloat(product.price).toFixed(2)}</td>
                       <td>${product.discount}</td>
                       <td>${product.price - product.price * product.discount / 100}</td>
                       <td>${product.gst / 2}</td>
                       <td>${(product.price - product.price * product.discount / 100) * product.quantity * product.gst / 200}</td>
                       <td>${product.gst / 2}</td>
                       <td>${(product.price - product.price * product.discount / 100) * product.quantity * product.gst / 200}</td>
                       <td>${((product.price - product.price * product.discount / 100) * product.quantity).toFixed(2)}</td>
                     </tr>
                   ` : "")).join('')}
        </tbody>
    </table>

    <!-- Summary Section -->
    <div class="summary">
        <p><strong>Sub Total: </strong>${billingProducts.total}</p>
        <p><strong>Packaging & Handling: </strong>**</p>
        <p><strong>CGST: </strong>123456</p>
        <p><strong>SGST: </strong>123456</p>
        <p><strong>GRAND TOTAL INR: </strong>124567890</p>
    </div>

    <!-- Footer Section -->
    <div class="footer">
        <!-- Terms and Conditions on the left -->
        <div class="footer-1">
            <div class="terms-conditions">
                <h3>Terms & Conditions</h3>
                <p>1. All goods remain the property of AVRN Intellitech Pvt Ltd until fully paid.</p>
                <p>2. Goods will be dispatched only after full payment is received.</p>
                <p>3. The quotation is valid for 30 days from the date of issue.</p>
                <p>4. Any disputes are subject to Bhopal jurisdiction.</p>
            </div>
    
            <!-- Authorized Signature on the right -->
            <div class="authorized-signature">
                <p><strong>For AVRN Intellitech Pvt Ltd</strong></p>
                <br><br>
                <p>Authorized Signature</p>
            </div>
        </div>
        
        <div class="banner" height="20%">
            <img src=${selectedCompany.banner} alt="Banner" width="100%">
        </div>
    </div>

    <script>
        // Sample dynamic data
        const products = ${quotationData.items}

        // Function to dynamically insert product rows into the table
        function insertProductRows() {
            const productRows = document.getElementById('product-rows');
            productRows.innerHTML = ''; // Clear existing rows

            products.forEach((product, index) => {
                const row = 
                    <tr>
                        
                        <td>{product.name}</td>
                        <td>{product.hsn}</td>
                        <td>{product.quantity}</td>
                        <td>{product.price}</td>
                        <td>{product.discount}</td>
                        <td>{product.gst}</td>
                        <td>{product.total}</td>
                    </tr>
                ;
                productRows.insertAdjacentHTML('beforeend', row);
            });
        }

        // Call the function to insert product rows
        insertProductRows();
    </script>
</body>
</html>

        `;
      };

      if (response.status = 200) {
        toggleComponent('CreateQuotation');
        console.log(response);
        const htmlContent = createHtmlString();

        // Open a new window
        const printWindow = window.open('', '', 'width=800,height=600');

        if (printWindow) {
          // Write the HTML string into the new window
          printWindow.document.write(htmlContent);
          printWindow.document.close(); // Close the document for further writing
          // printWindow.focus(); // Focus the new window
          printWindow.print(); // Trigger the print dialog
          // printWindow.close(); // Close the print window after printing
        }
        // printWindow.print();
      }
    } catch (error) {
      toast.error("Failed to create quotation.");
      console.error('Error submitting quotation:', error);
    }


  };
  const calculateCompleteTotal = () => {
    return String(billingProducts.reduce((total, product) => total + (product.total || 0), 0));
  };
  //   const handleTermsChange = (index, value) => {
  //     const newTerms = [...termsAndConditions];
  //     newTerms[index] = value;
  //     setTermsAndConditions(newTerms);
  // };
  const handleTermsChange = (index, value) => {
    // Create a new array to avoid direct state mutation
    const newTerms = [...terms];
    newTerms[index] = value;  // Update the specific term at the given index
    setTerms(newTerms);  // Update the state with the new array
    console.log(terms);
  };


  return (
    <div className="CreateQuotation-component">
      <h2 className='create-bill-heading'>Create Quatation</h2>
      <div className="bill-type-container">
        <label htmlFor="billType" className="bill-type-label">
          Select Quatation Type:
        </label>
        <div>
          <select
            id="billType"
            value={billType}
            onChange={handleBillTypeChange}
            className="bill-type-select"
          >
            <option value="PI">Select</option>
            <option value="PI">Proforma Invoice</option>
            <option value="Quotation">Quotation</option>
          </select>
        </div>

      </div>

      <div className="section">
        {/* Company and Client Selection */}
        <div className="row">
          {/* <div className="company-section"> */}
            {/* <label>Select Company:</label>
            <select onChange={handleCompanyChange}>
              <option value="">Select a company</option>
              {companies.map(company => (
                <option key={company.id} value={company.id}>{company.companyName}</option>
              ))}
            </select> */}
            {/* {selectedCompany && (
              <div className="company-details">
                <p><strong>Name:</strong> {selectedCompany.companyName}</p>
                <p><strong>GST No:</strong> {selectedCompany.gstNo}</p>
                <p><strong>Email:</strong> {selectedCompany.email}</p>
                <p><strong>Phone:</strong> {selectedCompany.phone}</p>
                <p><strong>Address:</strong> {selectedCompany.address}</p>
                {billType=="pi" && <div>
                  <p><strong>Account Name:</strong> {selectedCompany.accountName}</p>
                  <p><strong>Bank Name:</strong> {selectedCompany.bankName}</p>
                  <p><strong>Account Number:</strong> {selectedCompany}</p>
                  </div>}
              </div>
            )} */}
          {/* </div> */}

          <div className="client-section">
            <label>Select Client:</label>
            <input
              type="text"
              value={clientSearchTerm}
              onChange={(e) => {
                const newValue = e.target.value;
                setClientSearchTerm(newValue);
                if (!newValue) {
                  setSelectedClient(null); // Reset the selected client if the input is cleared
                }
              }}
              placeholder="Type client name, nickname, or GST number"
            />

            {/* Display suggestions only when there's a search term and no client is selected */}
            {clientSearchTerm && !selectedClient && (
              <ul className="suggestions-list">
                {clientSuggestions.length > 0 ? (
                  clientSuggestions.map((client) => (
                    <li key={client.id} onClick={() => handleClientChange(client)}>
                      {client.name} - {client.nickname} - {client.gstNo}
                    </li>
                  ))
                ) : (
                  <li>No clients found</li>
                )}
              </ul>
            )}
            {selectedClient && (
              <div className="client-details">
                <p><strong>Name:</strong> {selectedClient.name}</p>
                <p><strong>Nickname:</strong> {selectedClient.nickname}</p>
                <p><strong>Email:</strong> {selectedClient.email}</p>
                <p><strong>GST No.:</strong> {selectedClient.gstNo}</p>
                <p><strong>Phone:</strong> {selectedClient.phone}</p>
                <p><strong>Address:</strong> {selectedClient.address}</p>
                <p><strong>Contact Person:</strong> {selectedClient.contactPerson}</p>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Product Table */}
      <div className="section">
        <label>Products</label>
        <table className="product-table">
          <thead>
            <tr>
              <th>SR. No</th>
              <th className='product-name-input'>Product Name</th>
              <th>Product Id</th>
              <th>HSN</th>
              <th>Brand</th>
              <th>Variant</th>
              <th>Quantity</th>
              <th>Discount (%)</th>
              <th>GST (%)</th>
              <th>Price</th>
              <th>Total</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {billingProducts.map((product, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  <input
                    type="text"
                    value={product.name}
                    onChange={(e) => handleChange(index, 'name', e.target.value)}
                    placeholder="Search product"
                  />
                  {
                    console.log("Suggestion Data"+product.suggestions)
                  }
                  {product.suggestions && <ul className="suggestions-list">
                    {product.suggestions.map((suggestion) => (
                      <li key={suggestion.id} onClick={() => handleSelectProduct(index, suggestion)}>
                        {suggestion.name}
                      </li>
                    ))}
                  </ul>}
                </td>
                <td><input type="text" value={product.id} onChange={(e) => handleChange(index, 'id', e.target.value)} readOnly /></td>
                <td><input type="text" value={product.hsn} onChange={(e) => handleChange(index, 'hsn', e.target.value)} readOnly /></td>
                <td><input type="text" value={product.brand} onChange={(e) => handleChange(index, 'brand', e.target.value)} readOnly /></td>
                <td><input type="text" value={product.variant} onChange={(e) => handleChange(index, 'variant', e.target.value)} readOnly /></td>
                <td><input type="number" value={product.quantity} onChange={(e) => handleChange(index, 'quantity', e.target.value)} /></td>
                <td><input type="number" value={product.discount} onChange={(e) => handleChange(index, 'discount', e.target.value)} /></td>
                <td><input type="number" value={product.gst} onChange={(e) => handleChange(index, 'gst', e.target.value)} readOnly /></td>
                <td><input type="number" value={product.price} onChange={(e) => handleChange(index, 'price', e.target.value)} /></td>
                <td><input type="number" value={product.total} readOnly /></td>
                <td>
                  {<button onClick={() => handleRemoveRow(index)}>X</button>}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button className="add-row" onClick={handleAddRow}>Add Row</button>
      </div>
      <div className="section">
        <h3>Complete Total: {calculateCompleteTotal()} Rs</h3>
      </div>

      {/* Terms and Conditions */}
      <label>Terms and Conditions</label>
      {terms.map((term, index) => (
        <input
          type='text'
          key={index}
          value={term}
          onChange={(e) => handleTermsChange(index, e.target.value)}
          placeholder="Enter terms and conditions"
        />
      ))}

      {/* Submit Button */}


      <div className="section">
        <button className="submit-btn" onClick={handleSubmit}>Submit Quotation</button>
      </div>

    </div>
  );
};

export default CreateQuotation;
