export const login = async (email, password) => {
  // const response = await fetch('https://emkayindia.in/api/v1/login', {
    const response = await fetch('https://emkayindia.in/api/v1/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password }),
  });

  if (!response.ok) {
    throw new Error('Login failed');
  }

  const data = await response.json();
  localStorage.setItem("token", data.token);
  return data.token; // Assuming the API returns a token
};
