// import React, { useState } from 'react';
// import axios from 'axios';
// import './css/AddClient.css';

// const AddClient = ({ selectedCompany }) => {
// const [clientData, setClientData] = useState({
//   name: '',
//   nickname: '',
//   email: '',
//   phone: '',
//   address: '',
//   gstNo: '',
//   contactPerson: '',
//   contactEmail: '',
//   contactPhone: '',
//   companyId: []
// });

// const [error, setError] = useState('');
// const [successMessage, setSuccessMessage] = useState('');

// // Handle input changes
// const handleChange = (e) => {
//   setClientData({
//     ...clientData,
//     [e.target.name]: e.target.value
//   });
// };

// // Handle company selection (multi-select)
// const handleCompanyChange = (e) => {
//   const options = Array.from(e.target.selectedOptions, (option) => option.value);
//   setClientData({ ...clientData, companyId: options });
// };

// // Handle form submission
// const handleSubmit = async (e) => {
//   const token = localStorage.getItem('token');
//   e.preventDefault();
//   try {
//     const response = await axios.post(
//       'http://localhost:3000/api/v1/client/create',
//       clientData,
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//           'Content-Type': 'application/json'
//         }
//       }
//     );
//     setSuccessMessage('Client created successfully!');
//     setError('');
//     console.log(response.data);
//   } catch (err) {
//     setError('Failed to create client. Please try again.');
//     console.error(err);
//   }
// };

//   return (
//     <div className="add-client-container">
//       <h2 className="form-title">Add New Client</h2>
//       {error && <p className="error-message">{error}</p>}
//       {successMessage && <p className="success-message">{successMessage}</p>}

//       <form onSubmit={handleSubmit} >
//         <div className='form-container-addclient'>
//           <div className="form-section">
//             <label>Name:</label>
//             <input type="text" name="name" value={clientData.name} onChange={handleChange} required />

//             <label>Nickname:</label>
//             <input type="text" name="nickname" value={clientData.nickname} onChange={handleChange} required />

//             <label>Email:</label>
//             <input type="email" name="email" value={clientData.email} onChange={handleChange} required />

//             <label>Phone:</label>
//             <input type="tel" name="phone" value={clientData.phone} onChange={handleChange} required />
//           </div>

//           <div className="form-section">
//             <label>Address:</label>
//             <input type="text" name="address" value={clientData.address} onChange={handleChange} required />

//             <label>GST No:</label>
//             <input type="text" name="gstNo" value={clientData.gstNo} onChange={handleChange} required />

//             <label>Contact Person:</label>
//             <input type="text" name="contactPerson" value={clientData.contactPerson} onChange={handleChange} required />

//             <label>Contact Email:</label>
//             <input type="email" name="contactEmail" value={clientData.contactEmail} onChange={handleChange} required />

//             <label>Contact Phone:</label>
//             <input type="tel" name="contactPhone" value={clientData.contactPhone} onChange={handleChange} required />
//           </div>
//         </div>


//         <div className="form-section">
//           {selectedCompany ? <label>Selected Company: {selectedCompany.companyName}</label> : <label>Select A company First</label>}
//         </div>

//         <button className="submit-btn" type="submit">Create Client</button>
//       </form>
//     </div>
//   );
// };

// export default AddClient;
import React, { useState } from 'react';
import './css/AddClient.css';
import axios from 'axios';
import { toast } from 'react-toastify';

const AddClient = ({ selectedCompany,toggleComponnent }) => {
  const [clientData, setClientData] = useState({
    name: '',
    nickname: '',
    email: '',
    phone: '',
    address: '',
    gstNo: '',
    contactPerson: '',
    contactEmail: '',
    contactPhone: '',
    companyId: []
  });

  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Handle input changes
  const handleChange = (e) => {
    setClientData({
      ...clientData,
      [e.target.name]: e.target.value
    });
  };

  // Handle company selection (multi-select)
  const handleCompanyChange = (e) => {
    const options = Array.from(e.target.selectedOptions, (option) => option.value);
    setClientData({ ...clientData, companyId: options });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    const token = localStorage.getItem('token');
    e.preventDefault();
    try {
      const response = await axios.post(
        'http://localhost:3000/api/v1/client/create',
        clientData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      // setSuccessMessage('Client created successfully!');
      // setError('');
      toast.success("Client Added");
      toggleComponnent("AddClient");
      console.log(response.data);
    } catch (err) {
      // setError('Failed to create client. Please try again.');
      // console.error(err);
      toast.error("Error Occured while Adding Client");
    }
  };
  return (
    <div className="add-client-container">
      <h2 className="form-title">Add New Client</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-container-addclient">
          <div className="form-section">
            <label>Name:</label>
            <input type="text" name="name" value={clientData.name} onChange={handleChange} required />

            <label>Nickname:</label>
            <input type="text" name="nickname" value={clientData.nickname} onChange={handleChange} required />

            <label>Email:</label>
            <input type="email" name="email" value={clientData.email} onChange={handleChange} required />

            <label>Phone:</label>
            <input type="tel" name="phone" value={clientData.phone} onChange={handleChange} required />
          </div>

          <div className="form-section">
            <label>Address:</label>
            <input type="text" name="address" value={clientData.address} onChange={handleChange} required />

            <label>GST No:</label>
            <input type="text" name="gstNo" value={clientData.gstNo} onChange={handleChange} required />

            <label>Contact Person:</label>
            <input type="text" name="contactPerson" value={clientData.contactPerson} onChange={handleChange} required />

            <label>Contact Email:</label>
            <input type="email" name="contactEmail" value={clientData.contactEmail} onChange={handleChange} required />

          </div>
          <div className='form-section'>
            <label>Contact Phone:</label>
            <input type="tel" name="contactPhone" value={clientData.contactPhone} onChange={handleChange} required />

          </div>
        </div>

        <div className="selected-company">
          {selectedCompany ? (
            <label>Selected Company: {selectedCompany.companyName}</label>
          ) : (
            <label>Select a company first</label>
          )}
        </div>
        <div className='btn-container-submit'>
          <button className="submit-btn" type="submit">Create Client</button>
        </div>
        <button className="submit-btn" type="submit">Create Client</button>
      </form>
    </div>
  );
};

export default AddClient;
