import React, { useState } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import '../css/AddCompany.css'
import { toast } from 'react-toastify';

const AddCompany = ({ toggleComponent }) => {
  const [companyName, setCompanyName] = useState('');
  const [gstNo, setGstNo] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [logo, setLogo] = useState('');
  const [banner, setBanner] = useState('');
  const [terms, setTerms] = useState([{ id: 1, text: '' }]); // Array for terms
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleTermChange = (index, event) => {
    const updatedTerms = [...terms];
    updatedTerms[index].text = event.target.value;
    setTerms(updatedTerms);
  };

  const addTerm = () => {
    setTerms([...terms, { id: terms.length + 1, text: '' }]);
  };

  const removeTerm = (index) => {
    const updatedTerms = terms.filter((_, i) => i !== index);
    setTerms(updatedTerms);
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const token = localStorage.getItem('token');
  //     const decodedToken = jwtDecode(token);

  //     const companyData = {
  //       companyName,
  //       gstNo,
  //       email,
  //       phone,
  //       address,
  //       logo,
  //       banner,
  //       createdBy: decodedToken.id,
  //       terms: {
  //         termsAndConditions: terms.map(term => term.text),
  //       },
  //     };

  //     const response = await axios.post('https://emkayindia.in/api/v1/company/create', companyData, {
  //       headers: {
  //         'Authorization': `Bearer ${token}`,
  //         'Content-Type': 'application/json',
  //       },
  //     });

  //     setSuccess('Company created successfully');
  //     console.log('Success:', response.data);

  //     setCompanyName('');
  //     setGstNo('');
  //     setEmail('');
  //     setPhone('');
  //     setAddress('');
  //     setLogo('');
  //     setBanner('');
  //     setTerms([{ id: 1, text: '' }]);
  //   } catch (err) {
  //     setError('Failed to create company');
  //     console.error('Error:', err);
  //   }
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Get token from localStorage
      const token = localStorage.getItem('token');
      const decodedToken = jwtDecode(token);

      // Prepare company data with terms
      const companyData = {
        companyName,
        gstNo,
        email,
        phone,
        address,
        logo,
        banner,
        createdBy: decodedToken.id,
        terms: {
          termsAndConditions: terms.map(term => term.text), // Array of terms
        },
      };

      console.log("Sending company data:", companyData);

      // Send POST request to create company
      const response = await axios.post(
        'https://emkayindia.in/api/v1/company/create',
        companyData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // Success handling
      setSuccess('Company created successfully');
      console.log('Success:', response.data);
      toast.success('Comapany Added');
      toggleComponent('AddCompany');

      // Clear form fields after success
      setCompanyName('');
      setGstNo('');
      setEmail('');
      setPhone('');
      setAddress('');
      setLogo('');
      setBanner('');
      setTerms([{ id: 1, text: '' }]);
    } catch (err) {
      // Handle different types of errors and log them
      if (err.response) {
        console.error('Response error:', err.response.data);
        setError(`Error: ${err.response.data.message || 'Failed to create company'}`);
      } else if (err.request) {
        console.error('Request error:', err.request);
        setError('Error: No response from the server.');
      } else {
        console.error('Error:', err.message);
        setError('Error: Failed to create company.');
      }
    }
  };


  return (
    <div className="add-company-form">
      <h2 className='text-black-700'>Add Company</h2>
      <form onSubmit={handleSubmit} className='add-company-inner-form'>
        <div>
          <div className="form-group-add-company">
            <label>Company Name:</label>
            <input
              type="text"
              className="input-field-add-company"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              required
            />
          </div>
          <div className="form-group-add-company">
            <label>GST No:</label>
            <input
              type="text"
              className="input-field-add-company"
              value={gstNo}
              onChange={(e) => setGstNo(e.target.value)}
              required
            />
          </div>
          <div className="form-group-add-company">
            <label>Logo URL:</label>
            <input
              type="text"
              className="input-field-add-company"
              value={logo}
              onChange={(e) => setLogo(e.target.value)}
              required
            />
          </div>

        </div>
        <div>
          <div className="form-group-add-company">
            <label>Phone:</label>
            <input
              type="text"
              className="input-field-add-company"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </div>
          <div className="form-group-add-company">
            <label>Address:</label>
            <textarea
              
              className="input-field-add-company textarea-address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </div>

        </div>
        <div>
          <div className="form-group-add-company">
            <label>Email:</label>
            <input
              type="email"
              className="input-field-add-company"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group-add-company">
            <label>Banner URL:</label>
            <input
              type="text"
              className="input-field-add-company"
              value={banner}
              onChange={(e) => setBanner(e.target.value)}
              required
            />
          </div>
        </div>
        <button type="submit" className="submit-button-add-product">Add Company</button>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {success && <p style={{ color: 'green' }}>{success}</p>}

      </form>

      {/* Dynamic Terms and Conditions Section */}
      <div className="terms-container">
        <label>Terms and Conditions:</label>
        <div className='terms-sec'>
          {terms.map((term, index) => (
            <div key={term.id} className="term-item">
              <div className='input-remove'>
                <input
                  type="text"
                  className="input-field-terms"
                  value={term.text}
                  onChange={(e) => handleTermChange(index, e)}
                  placeholder={`Term #${index + 1}`}
                  required
                />
                {terms.length > 1 && (
                  <button
                    type="button"
                    className="remove-button"
                    onClick={() => removeTerm(index)}
                  >
                    Remove
                  </button>
                )}


              </div>

            </div>
          ))}
          <div>
            {
              terms.length <= 4 && <button type="button" className="add-button" onClick={addTerm}>
                Add Term
              </button>
            }
          </div>

        </div>



      </div>



    </div>
  );
};

export default AddCompany;

