// // import React, { useState, useEffect } from 'react';
// // import axios from 'axios';
// // import '../css/AddProduct.css';
// // import { toast } from 'react-toastify';

// // const AddProduct = ({toggleComponent}) => {
// //     const [productData, setProductData] = useState({
// //         name: '',
// //         description: '',
// //         price: '',
// //         gst: '',
// //         hsn: '',
// //         category: '',
// //         image1: '',
// //         companyId: [],
// //     });

// //     const [companies, setCompanies] = useState([]);

// //     // Fetch the list of companies
// //     useEffect(() => {
// //         // Retrieve JWT token from localStorage
// //         const token = localStorage.getItem('token');

// //         // Fetch companies on component mount using Axios
// //         axios.get('https://emkayindia.in/api/v1/company/all', {
// //             headers: {
// //                 'Authorization': `Bearer ${token}`
// //             }
// //         })
// //             .then(response => {
// //                 setCompanies(response.data); // Assuming the API returns companies in the response data
// //             })
// //             .catch(error => {
// //                 console.error('Error fetching companies:', error);
// //             });
// //     }, []);


// //     // Handle form input changes
// //     const handleInputChange = (e) => {
// //         const { name, value } = e.target;
// //         setProductData({ ...productData, [name]: value });
// //     };

// //     // Handle checkbox changes for company selection
// //     const handleCheckboxChange = (e) => {
// //         const { value, checked } = e.target;
// //         setProductData(prevState => ({
// //             ...prevState,
// //             companyId: checked
// //                 ? [...prevState.companyId, value]
// //                 : prevState.companyId.filter(id => id !== value)
// //         }));
// //     };

// //     // Handle form submission
// //     const handleSubmit = (e) => {
// //         e.preventDefault();
// //         const productDataToSend = {
// //             ...productData,
// //             price: parseFloat(productData.price), // Convert price to a float
// //             gst: parseFloat(productData.gst) // Also, convert gst to a float if needed
// //           };
// //         const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage

// //         axios.post('https://emkayindia.in/api/v1/product/create', productDataToSend, {
// //             headers: {
// //                 'Authorization': `Bearer ${token}`  // Add the JWT token to the Authorization header
// //             }
// //         })
// //             .then(response => {
// //                 console.log('Product added successfully:', response.data);
// //                 toast.success("Product added");
// //                 // if (onProductAdded) {
// //                toggleComponent("AddProduct")
// //                 // }
// //             })
// //             .catch(error => console.error('Error adding product:', error));
// //     };
// //     const [isDropdownOpen, setIsDropdownOpen] = useState(false);

// //     const toggleDropdown = () => {
// //         setIsDropdownOpen(!isDropdownOpen);
// //     };


// //     return (
// //         <form className="product-form" onSubmit={handleSubmit}>
// //             <h2>Add New Product</h2>
// //             <div className="dropdown">
// //                 <button className="dropdown-button" onClick={toggleDropdown}>
// //                     Select Companies
// //                 </button>
// //                 {isDropdownOpen && (
// //                     <div className="dropdown-content">
// //                         {companies.map(company => (
// //                             <div>
// //                                 <label >
// //                                     <input
// //                                         type="checkbox"
// //                                         value={company.id}
// //                                         onChange={handleCheckboxChange}
// //                                     />
// //                                 </label>
// //                                 <p className='company-name'>{company.companyName}</p>

// //                             </div>
// //                         ))}
// //                     </div>
// //                 )}
// //             </div>
// //             <label>
// //                 Product Name:
// //                 <input
// //                     type="text"
// //                     name="name"
// //                     value={productData.name}
// //                     onChange={handleInputChange}
// //                     required
// //                 />
// //             </label>

// //             <label>
// //                 Description:
// //                 <textarea
// //                     name="description"
// //                     value={productData.description}
// //                     onChange={handleInputChange}
// //                     required
// //                 />
// //             </label>

// //             <label>
// //                 Price:
// //                 <input
// //                     type="number"
// //                     name="price"
// //                     value={productData.price}
// //                     onChange={handleInputChange}
// //                     required
// //                 />
// //             </label>

// //             <label>
// //                 GST:
// //                 <input
// //                     type="number"
// //                     name="gst"
// //                     value={productData.gst}
// //                     onChange={handleInputChange}
// //                     required
// //                 />
// //             </label>

// //             <label>
// //                 HSN:
// //                 <input
// //                     type="text"
// //                     name="hsn"
// //                     value={productData.hsn}
// //                     onChange={handleInputChange}
// //                     required
// //                 />
// //             </label>

// //             <label>
// //                 Category:
// //                 <input
// //                     type="text"
// //                     name="category"
// //                     value={productData.category}
// //                     onChange={handleInputChange}
// //                     required
// //                 />
// //             </label>

// //             <label>
// //                 Image URL:
// //                 <input
// //                     type="text"
// //                     name="image1"
// //                     value={productData.image1}
// //                     onChange={handleInputChange}
// //                 />
// //             </label>

            

// //             <button type="submit">Add Product</button>
// //         </form>
// //     );
// // };

// // export default AddProduct;
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import '../css/AddProduct.css';
// import { toast } from 'react-toastify';

// const AddProduct = ({ toggleComponent }) => {
//   const [productData, setProductData] = useState({
//     name: '',
//     description: '',
//     description1: '',
//     price: '',
//     gst: '',
//     hsn: '',
//     category: '',
//     subCategory: '',
//     brand: '',
//     variant: '',
//     variant1: '',
//     variant2: '',
//     variant3: '',
//     variant4: '',
//     companyId: [],
//     images: [''], // For dynamic image input fields
//   });

//   const [companies, setCompanies] = useState([]);
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);

//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     axios.get('https://emkayindia.in/api/v1/company/all', {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     })
//     .then(response => setCompanies(response.data))
//     .catch(error => console.error('Error fetching companies:', error));
//   }, []);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setProductData({ ...productData, [name]: value });
//   };

//   const handleCheckboxChange = (e) => {
//     const { value, checked } = e.target;
//     setProductData(prevState => ({
//       ...prevState,
//       companyId: checked
//         ? [...prevState.companyId, value]
//         : prevState.companyId.filter(id => id !== value),
//     }));
//   };

//   const handleImageChange = (index, value) => {
//     const updatedImages = [...productData.images];
//     updatedImages[index] = value;
//     setProductData({ ...productData, images: updatedImages });
//   };

//   const addImageField = () => {
//     setProductData(prevState => ({
//       ...prevState,
//       images: [...prevState.images, ''],
//     }));
//   };

//   const removeImageField = (index) => {
//     setProductData(prevState => ({
//       ...prevState,
//       images: prevState.images.filter((_, i) => i !== index),
//     }));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const productDataToSend = {
//       ...productData,
//       price: parseFloat(productData.price),
//       gst: parseFloat(productData.gst),
//     };
//     const token = localStorage.getItem('token');
//     axios.post('https://emkayindia.in/api/v1/product/create', productDataToSend, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     })
//     .then(response => {
//       toast.success('Product added successfully!');
//       toggleComponent('AddProduct');
//     })
//     .catch(error => console.error('Error adding product:', error));
//   };

//   const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

//   return (
//     <div className="add-product-container">
//     <form className="product-form" onSubmit={handleSubmit}>
//       <h2>Add New Product</h2>

//       {/* Product Details Section */}
//       <div className="form-section">
//         <h3>Product Details</h3>
//         <div className="form-row">
//           <label>
//             Name:
//             <input type="text" name="name" value={productData.name} onChange={handleInputChange} required />
//           </label>
//           <label>
//             Price:
//             <input type="number" name="price" value={productData.price} onChange={handleInputChange} required />
//           </label>
//           <label>
//             GST:
//             <input type="number" name="gst" value={productData.gst} onChange={handleInputChange} required />
//           </label>
//         </div>
//         <div className="form-row">
//           <label>
//             Description:
//             <textarea name="description" value={productData.description} onChange={handleInputChange} required />
//           </label>
//           <label>
//             Additional Description:
//             <textarea name="description1" value={productData.description1} onChange={handleInputChange} />
//           </label>
//           <label>
//             HSN:
//             <input type="text" name="hsn" value={productData.hsn} onChange={handleInputChange} required />
//           </label>
//         </div>
//         <div className="form-row">
//           <label>
//             Category:
//             <input type="text" name="category" value={productData.category} onChange={handleInputChange} required />
//           </label>
//           <label>
//             SubCategory:
//             <input type="text" name="subCategory" value={productData.subCategory} onChange={handleInputChange} />
//           </label>
//           <label>
//             Brand:
//             <input type="text" name="brand" value={productData.brand} onChange={handleInputChange} />
//           </label>
//         </div>
//       </div>

//       {/* Product Variants Section */}
//       <div className="form-section">
//         <h3>Product Variants</h3>
//         <div className="form-row">
//           <label>
//             Variant 1:
//             <input type="text" name="variant" value={productData.variant} onChange={handleInputChange} />
//           </label>
//           <label>
//             Variant 2:
//             <input type="text" name="variant1" value={productData.variant1} onChange={handleInputChange} />
//           </label>
//           <label>
//             Variant 3:
//             <input type="text" name="variant2" value={productData.variant2} onChange={handleInputChange} />
//           </label>
//         </div>
//         <div className="form-row">
//           <label>
//             Variant 4:
//             <input type="text" name="variant3" value={productData.variant3} onChange={handleInputChange} />
//           </label>
//           <label>
//             Variant 5:
//             <input type="text" name="variant4" value={productData.variant4} onChange={handleInputChange} />
//           </label>
//         </div>
//       </div>

//       {/* Company Selection */}
//       <div className="dropdown">
//         <button className="dropdown-button" onClick={toggleDropdown}>Select Companies</button>
//         {isDropdownOpen && (
//           <div className="dropdown-content">
//             {companies.map(company => (
//               <div key={company.id}>
//                 <label>
//                   <input type="checkbox" value={company.id} onChange={handleCheckboxChange} />
//                   {company.companyName}
//                 </label>
//               </div>
//             ))}
//           </div>
//         )}
//       </div>

//       {/* Image Section with Dynamic Fields */}
//       <div className="form-section">
//         <h3>Product Images</h3>
//         {productData.images.map((image, index) => (
//           <div key={index} className="form-row">
//             <label>
//               Image {index + 1}:
//               <input type="text" value={image} onChange={e => handleImageChange(index, e.target.value)} />
//             </label>
//             <button type="button" onClick={() => removeImageField(index)}>Remove</button>
//           </div>
//         ))}
//         <button type="button" onClick={addImageField}>Add Image</button>
//       </div>

//       <button type="submit">Add Product</button>
//     </form>
//     </div>
//   );
  
// };

// export default AddProduct;
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import '../css/AddProduct.css';
// import { toast } from 'react-toastify';

// const AddProduct = ({ toggleComponent }) => {
//   const [productData, setProductData] = useState({
//     name: '',
//     description: '',
//     description1: '',
//     price: '',
//     gst: '',
//     hsn: '',
//     category: '',
//     subCategory: '',
//     brand: '',
//     variant: '',
//     variant1: '',
//     variant2: '',
//     variant3: '',
//     variant4: '',
//     companyId: [],
//     images: [''], // For dynamic image input fields
//   });

//   const [companies, setCompanies] = useState([]);
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);

//   // Fetch companies
//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     axios.get('https://emkayindia.in/api/v1/company/all', {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       }
//     })
//     .then(response => setCompanies(response.data))
//     .catch(error => console.error('Error fetching companies:', error));
//   }, []);

//   // Handle input changes
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setProductData({ ...productData, [name]: value });
//   };

//   // Handle company checkbox selection
//   const handleCheckboxChange = (e) => {
//     const { value, checked } = e.target;
//     setProductData(prevState => ({
//       ...prevState,
//       companyId: checked
//         ? [...prevState.companyId, value]
//         : prevState.companyId.filter(id => id !== value),
//     }));
//   };

//   // Handle image input changes
//   const handleImageChange = (index, value) => {
//     const updatedImages = [...productData.images];
//     updatedImages[index] = value;
//     setProductData({ ...productData, images: updatedImages });
//   };

//   // Add image field
//   const addImageField = () => {
//     setProductData(prevState => ({
//       ...prevState,
//       images: [...prevState.images, ''],
//     }));
//   };

//   // Remove image field
//   const removeImageField = (index) => {
//     setProductData(prevState => ({
//       ...prevState,
//       images: prevState.images.filter((_, i) => i !== index),
//     }));
//   };

//   // Handle form submission
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (!productData.companyId.length) {
//       toast.error('Please select at least one company.');
//       return;
//     }

//     const productDataToSend = {
//       ...productData,
//       price: parseFloat(productData.price),
//       gst: parseFloat(productData.gst),
//     };

//     const token = localStorage.getItem('token');
//     axios.post('https://emkayindia.in/api/v1/product/create', productDataToSend, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     })
//     .then(response => {
//       toast.success('Product added successfully!');
//       toggleComponent('AddProduct');
//     })
//     .catch(error => console.error('Error adding product:', error));
//   };

//   // Toggle dropdown state
//   const toggleDropdown = (e) => {
//     e.preventDefault(); // Prevent the form from submitting on dropdown click
//     setIsDropdownOpen(!isDropdownOpen);
//   };

//   return (
//     <div className="add-product-container">
//       <form className="product-form" onSubmit={handleSubmit}>
//         <h2>Add New Product</h2>

//         {/* Product Details Section */}
//         <div className="form-section">
//           <h3>Product Details</h3>
//           <div className="form-row">
//             <label>
//               Name:
//               <input type="text" name="name" value={productData.name} onChange={handleInputChange} required />
//             </label>
//             <label>
//               Price:
//               <input type="number" name="price" value={productData.price} onChange={handleInputChange} required />
//             </label>
//             <label>
//               GST:
//               <input type="number" name="gst" value={productData.gst} onChange={handleInputChange} required />
//             </label>
//           </div>
//           <div className="form-row">
//             <label>
//               Description:
//               <textarea name="description" value={productData.description} onChange={handleInputChange} required />
//             </label>
//             <label>
//               Additional Description:
//               <textarea name="description1" value={productData.description1} onChange={handleInputChange} />
//             </label>
//             <label>
//               HSN:
//               <input type="text" name="hsn" value={productData.hsn} onChange={handleInputChange} required />
//             </label>
//           </div>
//           <div className="form-row">
//             <label>
//               Category:
//               <input type="text" name="category" value={productData.category} onChange={handleInputChange} required />
//             </label>
//             <label>
//               SubCategory:
//               <input type="text" name="subCategory" value={productData.subCategory} onChange={handleInputChange} />
//             </label>
//             <label>
//               Brand:
//               <input type="text" name="brand" value={productData.brand} onChange={handleInputChange} />
//             </label>
//           </div>
//         </div>

//         {/* Product Variants Section */}
//         <div className="form-section">
//           <h3>Product Variants</h3>
//           <div className="form-row">
//             <label>
//               Variant 1:
//               <input type="text" name="variant" value={productData.variant} onChange={handleInputChange} />
//             </label>
//             <label>
//               Variant 2:
//               <input type="text" name="variant1" value={productData.variant1} onChange={handleInputChange} />
//             </label>
//             <label>
//               Variant 3:
//               <input type="text" name="variant2" value={productData.variant2} onChange={handleInputChange} />
//             </label>
//           </div>
//           <div className="form-row">
//             <label>
//               Variant 4:
//               <input type="text" name="variant3" value={productData.variant3} onChange={handleInputChange} />
//             </label>
//             <label>
//               Variant 5:
//               <input type="text" name="variant4" value={productData.variant4} onChange={handleInputChange} />
//             </label>
//           </div>
//         </div>

//         {/* Company Selection */}
//         <div className="dropdown">
//           <button className="dropdown-button" onClick={toggleDropdown}>Select Companies</button>
//           {isDropdownOpen && (
//             <div className="dropdown-content">
//               {companies.map(company => (
//                   <div key={company.id}>
//                     <label>
//                       <input
//                         type="checkbox"
//                         value={company.id}
//                         checked={companies.includes(company.id)}
//                         onChange={() => toggleDropdown(company.id)}
//                       />
//                       {company.companyName}
//                     </label>
//                   </div>
//                 ))}
//             </div>
//           )}
//         </div>

//         {/* Image Section with Dynamic Fields */}
//         <div className="form-section">
//           <h3>Product Images</h3>
//           {productData.images.map((image, index) => (
//             <div key={index} className="form-row">
//               <label>
//                 Image {index + 1}:
//                 <input type="text" value={image} onChange={e => handleImageChange(index, e.target.value)} />
//               </label>
              
//               <button type="button" onClick={() => removeImageField(index)}>Remove</button>
//             </div>
//           ))}
//           <button type="button" onClick={addImageField}>Add Image</button>
//         </div>

//         <button type="submit">Add Product</button>
//       </form>
//     </div>
//   );
// };

// export default AddProduct;
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import '../css/AddProduct.css';
// import { toast } from 'react-toastify';

// const AddProduct = ({ toggleComponent }) => {
//   const [productData, setProductData] = useState({
//     name: '',
//     description: '',
//     description1: '',
//     price: '',
//     gst: '',
//     hsn: '',
//     category: '',
//     subCategory: '',
//     brand: '',
//     variant: '',
//     variant1: '',
//     variant2: '',
//     variant3: '',
//     variant4: '',
//     companyId: [], // Array to store selected company IDs
//     images: [''], // For dynamic image input fields
//   });

//   const [companies, setCompanies] = useState([]);
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);

//   // Fetch companies
//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     axios.get('https://emkayindia.in/api/v1/company/all', {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       }
//     })
//     .then(response => setCompanies(response.data))
//     .catch(error => console.error('Error fetching companies:', error));
//   }, []);

//   // Handle input changes
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setProductData({ ...productData, [name]: value });
//   };

//   // Handle company checkbox selection
//   const handleCheckboxChange = (e) => {
//     const { value, checked } = e.target;
//     setProductData(prevState => ({
//       ...prevState,
//       companyId: checked
//         ? [...prevState.companyId, value]
//         : prevState.companyId.filter(id => id !== value),
//     }));
//   };

//   // Handle image input changes
//   const handleImageChange = (index, value) => {
//     const updatedImages = [...productData.images];
//     updatedImages[index] = value;
//     setProductData({ ...productData, images: updatedImages });
//   };

//   // Add image field
//   const addImageField = () => {
//     setProductData(prevState => ({
//       ...prevState,
//       images: [...prevState.images, ''],
//     }));
//   };

//   // Remove image field
//   const removeImageField = (index) => {
//     setProductData(prevState => ({
//       ...prevState,
//       images: prevState.images.filter((_, i) => i !== index),
//     }));
//   };

//   // Handle form submission
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (!productData.companyId.length) {
//       toast.error('Please select at least one company.');
//       return;
//     }

//     // Prepare the request body
//     const productDataToSend = {
//       name: productData.name,
//       description: productData.description,
//       description1: productData.description1,
//       price: parseFloat(productData.price),
//       gst: parseFloat(productData.gst),
//       hsn: productData.hsn,
//       category: productData.category,
//       subCategory: productData.subCategory,
//       brand: productData.brand,
//       variant: productData.variant,
//       variant1: productData.variant1,
//       variant2: productData.variant2,
//       variant3: productData.variant3,
//       variant4: productData.variant4,
//       companyId: productData.companyId,
//       ...productData.images.reduce((acc, image, index) => {
//         if (image) acc[`image${index + 1}`] = image;
//         return acc;
//       }, {}), // Flatten images array into image1, image2, etc.
//     };

//     const token = localStorage.getItem('token');
//     axios.post('https://emkayindia.in/api/v1/product/create', productDataToSend, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     })
//     .then(response => {
//       toast.success('Product added successfully!');
//       toggleComponent('AddProduct');
//     })
//     .catch(error => console.error('Error adding product:', error));
//   };

//   // Toggle dropdown state
//   const toggleDropdown = (e) => {
//     e.preventDefault(); // Prevent the form from submitting on dropdown click
//     setIsDropdownOpen(!isDropdownOpen);
//   };


//   return (
//     <div className="add-product-container">
//       <form className="product-form" onSubmit={handleSubmit}>
//         <h2>Add New Product</h2>

//         {/* Product Details Section */}
//         <div className="form-section">
//           <h3>Product Details</h3>
//           <div className="form-row">
//             <label>
//               Name:
//               <input type="text" name="name" value={productData.name} onChange={handleInputChange} required />
//             </label>
//             <label>
//               Price:
//               <input type="number" name="price" value={productData.price} onChange={handleInputChange} required />
//             </label>
//             <label>
//               GST:
//               <input type="number" name="gst" value={productData.gst} onChange={handleInputChange} required />
//             </label>
//           </div>
//           <div className="form-row">
//             <label>
//               Description:
//               <textarea name="description" value={productData.description} onChange={handleInputChange} required />
//             </label>
//             <label>
//               Additional Description:
//               <textarea name="description1" value={productData.description1} onChange={handleInputChange} />
//             </label>
//             <label>
//               HSN:
//               <input type="text" name="hsn" value={productData.hsn} onChange={handleInputChange} required />
//             </label>
//           </div>
//           <div className="form-row">
//             <label>
//               Category:
//               <input type="text" name="category" value={productData.category} onChange={handleInputChange} required />
//             </label>
//             <label>
//               SubCategory:
//               <input type="text" name="subCategory" value={productData.subCategory} onChange={handleInputChange} />
//             </label>
//             <label>
//               Brand:
//               <input type="text" name="brand" value={productData.brand} onChange={handleInputChange} />
//             </label>
//           </div>
//         </div>

//         {/* Product Variants Section */}
//         <div className="form-section">
//           <h3>Product Variants</h3>
//           <div className="form-row">
//             <label>
//               Variant 1:
//               <input type="text" name="variant" value={productData.variant} onChange={handleInputChange} />
//             </label>
//             <label>
//               Variant 2:
//               <input type="text" name="variant1" value={productData.variant1} onChange={handleInputChange} />
//             </label>
//             <label>
//               Variant 3:
//               <input type="text" name="variant2" value={productData.variant2} onChange={handleInputChange} />
//             </label>
//           </div>
//           <div className="form-row">
//             <label>
//               Variant 4:
//               <input type="text" name="variant3" value={productData.variant3} onChange={handleInputChange} />
//             </label>
//             <label>
//               Variant 5:
//               <input type="text" name="variant4" value={productData.variant4} onChange={handleInputChange} />
//             </label>
//           </div>
//         </div>

//         {/* Company Selection */}
//         <div className={`dropdown ${isDropdownOpen ? 'active' : ''}`}>
//   <button className="dropdown-button" onClick={toggleDropdown}>Select Companies</button>
//   {isDropdownOpen && (
//     <div className="dropdown-content">
//       {companies.map(company => (
//         <div key={company.id}>
//           <label>
//             <input
//               type="checkbox"
//               value={company.id}
//               checked={productData.companyId.includes(company.id)}
//               onChange={handleCheckboxChange}
//             />
//             {company.companyName}
//           </label>
//         </div>
//       ))}
      
//     </div>
//   )}
// </div>


//         {/* Image Section with Dynamic Fields */}
//         <div className="form-section">
//           <h3>Product Images</h3>
//           {productData.images.map((image, index) => (
//             <div key={index} className="form-row">
//               <label>
//                 Image {index + 1}:
//                 <input type="text" value={image} onChange={e => handleImageChange(index, e.target.value)} />
//               </label>
              
//               <button type="button" onClick={() => removeImageField(index)}>Remove</button>
//             </div>
//           ))}
//           <button type="button" onClick={addImageField}>Add Image</button>
//         </div>

//         <button type="submit">Add Product</button>
//       </form>
//     </div>
//   );
// };

// export default AddProduct;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/AddProduct.css';
import { toast } from 'react-toastify';

const AddProduct = ({ toggleComponent }) => {
  const [productData, setProductData] = useState({
    name: '',
    description: '',
    description1: '',
    price: '',
    gst: '',
    hsn: '',
    category: '',
    subCategory: '',
    brand: '',
    variant: '',
    variant1: '',
    variant2: '',
    variant3: '',
    variant4: '',
    companyId: [],
    images: [''], // For dynamic image input fields
  });

  const [companies, setCompanies] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Fetch companies
  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get('https://emkayindia.in/api/v1/company/all', {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    .then(response => setCompanies(response.data))
    .catch(error => console.error('Error fetching companies:', error));
  }, []);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductData({ ...productData, [name]: value });
  };

  // Handle company checkbox selection
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setProductData(prevState => ({
      ...prevState,
      companyId: checked
        ? [...prevState.companyId, value]
        : prevState.companyId.filter(id => id !== value),
    }));
  };

  // Handle image input changes
  const handleImageChange = (index, value) => {
    const updatedImages = [...productData.images];
    updatedImages[index] = value;
    setProductData({ ...productData, images: updatedImages });
  };

  // Add image field
  const addImageField = () => {
    setProductData(prevState => ({
      ...prevState,
      images: [...prevState.images, ''],
    }));
  };

  // Remove image field
  const removeImageField = (index) => {
    setProductData(prevState => ({
      ...prevState,
      images: prevState.images.filter((_, i) => i !== index),
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!productData.companyId.length) {
      toast.error('Please select at least one company.');
      return;
    }

    const productDataToSend = {
      ...productData,
      price: parseFloat(productData.price),
      gst: parseFloat(productData.gst),
      ...productData.images.reduce((acc, image, index) => {
        if (image) acc[`image${index + 1}`] = image;
        return acc;
      }, {}),
    };

    const token = localStorage.getItem('token');
    axios.post('https://emkayindia.in/api/v1/product/create', productDataToSend, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => {
      toast.success('Product added successfully!');
      toggleComponent('AddProduct');
    })
    .catch(error => console.error('Error adding product:', error));
  };

  // Toggle dropdown state
  const toggleDropdown = (e) => {
    e.preventDefault();
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Selected company names
  const selectedCompanyNames = companies
    .filter(company => productData.companyId.includes(company.id))
    .map(company => company.companyName);

  return (
    <div className="add-product-container">
      <form className="product-form" onSubmit={handleSubmit}>
        <h2>Add New Product</h2>

        {/* Product Details Section */}
        <div className="form-section">
          <h3>Product Details</h3>
          <div className="form-row">
            <label>
              Name:
              <input type="text" name="name" value={productData.name} onChange={handleInputChange} required />
            </label>
            <label>
              Price:
              <input type="number" name="price" value={productData.price} onChange={handleInputChange} required />
            </label>
            <label>
              GST:
              <input type="number" name="gst" value={productData.gst} onChange={handleInputChange} required />
            </label>
          </div>
          <div className="form-row">
            <label>
              Description:
              <textarea name="description" value={productData.description} onChange={handleInputChange} required />
            </label>
            <label>
              Additional Description:
              <textarea name="description1" value={productData.description1} onChange={handleInputChange} />
            </label>
            <label>
              HSN:
              <input type="text" name="hsn" value={productData.hsn} onChange={handleInputChange} required />
            </label>
          </div>
          <div className="form-row">
            <label>
              Category:
              <input type="text" name="category" value={productData.category} onChange={handleInputChange} required />
            </label>
            <label>
              SubCategory:
              <input type="text" name="subCategory" value={productData.subCategory} onChange={handleInputChange} />
            </label>
            <label>
              Brand:
              <input type="text" name="brand" value={productData.brand} onChange={handleInputChange} />
            </label>
          </div>
        </div>

        {/* Product Variants Section */}
        <div className="form-section">
          <h3>Product Variants</h3>
          <div className="form-row">
            <label>
              Variant 1:
              <input type="text" name="variant" value={productData.variant} onChange={handleInputChange} />
            </label>
            <label>
              Variant 2:
              <input type="text" name="variant1" value={productData.variant1} onChange={handleInputChange} />
            </label>
            <label>
              Variant 3:
              <input type="text" name="variant2" value={productData.variant2} onChange={handleInputChange} />
            </label>
          </div>
          <div className="form-row">
            <label>
              Variant 4:
              <input type="text" name="variant3" value={productData.variant3} onChange={handleInputChange} />
            </label>
            <label>
              Variant 5:
              <input type="text" name="variant4" value={productData.variant4} onChange={handleInputChange} />
            </label>
          </div>
        </div>

        {/* Company Selection */}
        <div className="company-selection">
          <div className={`dropdown ${isDropdownOpen ? 'active' : ''}`}>
            <button className="dropdown-button" onClick={toggleDropdown}>Select Companies</button>
            {isDropdownOpen && (
              <div className="dropdown-content">
                {companies.map(company => (
                  <div key={company.id}>
                    <label>
                      <input
                        type="checkbox"
                        value={company.id}
                        checked={productData.companyId.includes(company.id)}
                        onChange={handleCheckboxChange}
                      />
                      {company.companyName}
                    </label>
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Display selected companies in boxes */}
          <div className="selected-companies">
            {selectedCompanyNames.map((name, index) => (
              <div key={index} className="selected-company-box">{name}</div>
            ))}
          </div>
        </div>

        {/* Image Section with Dynamic Fields */}
        <div className="form-section">
          <h3>Product Images</h3>
          {productData.images.map((image, index) => (
            <div key={index} className="form-row">
              <label>
                Image {index + 1}:
                <input
                  type="text"
                  value={image}
                  onChange={e => handleImageChange(index, e.target.value)}
                  required={index === 0} // Require at least the first image field
                />
              </label>
              {index > 0 && (
                <button
                type="button"
                style={{ backgroundColor: 'red', color: 'white', border: 'none', padding: '8px 10px', cursor: 'pointer' }}
                onClick={() => removeImageField(index)}
              >
                Remove
              </button>
              
              )}
            </div>
          ))}
          <button type="button" onClick={addImageField} className='img-btn'>Add Image</button>
        </div>

        <button type="submit" className='add-product-btn'>Add Product</button>
      </form>
    </div>
  );
};

export default AddProduct;
