import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddCompany from './Pages/AddCompany';
import './css/Dashboard.css'
import CreateQuatation from './CreateQuatation';
import AddProduct from './Pages/AddProduct';
import CreateUser from './Pages/CreateUser';
import { ToastContainer } from 'react-toastify';
import QuotationList from './QuatationList';
import AddClient from './AddClient';

const hasPermission = (permissions, permissionName) => {
    return permissions.some(permission => permission.name === permissionName);
  };
const Dashboard = () => {
    const navigate = useNavigate();
    const [userName, setUserName] = useState("");
    const [userPermissions,setUserPermissions] = useState([]);
    const [userRole, setUserRole] = useState("");
    const [activeComponent, setActiveComponent] = useState(null);
    const [createUser, setCreateUser] = useState(false);
    const [companies,setCompanies] = useState([]);
    const [selectedCompany,setSelectedCompany] = useState(null);
    const [terms, setTerms] = useState([]);
    const [canCreateUser,setCanCreateUser] = useState();

    // Function to render the component based on button click
    const toggleComponent = (componentName) => {
        if (activeComponent === componentName) {
            // If the clicked component is already active, deactivate it
            setActiveComponent(null);
        } else {
            // Otherwise, activate the clicked component
            setActiveComponent(componentName);
        }
    };
    // Logout function
    const onLogout = () => {
        localStorage.clear();
        navigate('/');
    };

    // Event listener for browser back button
    const handlePopState = (e) => {
        onLogout();
        navigate('/');
        const confirmLogout = window.alert('Do you really want to leave? This will log you out.');
        // if (confirmLogout) {
        //     onLogout();  // Log out if confirmed
        // } 
        // else {
        //     e.preventDefault();  // Prevent back navigation
        //     window.history.pushState(null, '', window.location.href);  // Push state to prevent back
        // }
    };

    useEffect(() => {
        const getUserDetails = async () => {
            const token = localStorage.getItem('token');

            if (!token) {
                console.error('No token found');
                onLogout();
                navigate('/login');
                return;
            }

            try {
                const decodedToken = jwtDecode(token); // Correct the jwtDecode usage
                const userId = decodedToken.id;
                console.log('UserID:', userId);

                const response = await axios.get(`https://emkayindia.in/api/v1/user/get/${userId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                axios.get('https://emkayindia.in/api/v1/company/all', {
                    headers: { Authorization: `Bearer ${token}` }
                  })
                    .then(response => setCompanies(response.data))
                    .catch(error => console.error('Error fetching companies:', error));

                setUserName(response.data.name);
                setUserRole(response.data.role);
                setUserPermissions(response.data.permissions);
                if(userPermissions){
                    setCanCreateUser(hasPermission(userPermissions,"createUser"));
                }

                console.log('User Data:', response.data);
            } catch (err) {
                console.error('Get User Details Failed', err);
                onLogout();
                navigate('/login');
            }
        };

        // Add event listener for back button
        window.addEventListener('popstate',
             () => {
            onLogout();
            // handlePopState();
        //     const confirmLogout = window.alert('Do you really want to leave? This will log you out.');
        // if (confirmLogout) {
        //     onLogout();  // Log out if confirmed
        // } 
        // else {
        //     // preventDefault();  // Prevent back navigation
        //     window.history.pushState(null, '', window.location.href);  // Push state to prevent back
        // }
        }
    );

        // Fetch user details
        getUserDetails();

        // Cleanup on component unmount
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [navigate]); // Empty dependency array to prevent infinite re-renders
    const handleCompanyChange = (event) => {
        const companyId = event.target.value;
        const company = companies.find(c => c.id === companyId);
        setTerms(company.terms.termsAndConditions);
        setSelectedCompany(company);
        console.log(terms)
      };

    return (
        <div className='dashboard-container'>
            <div className='header'>
                {selectedCompany && <p>Selected Company :{selectedCompany.companyName}</p>}
                <p>Welcome  {userName}</p>
                {userRole && (
                    <h4>Role: {userRole}</h4>
                )}
                <button onClick={() => {
                    onLogout();  // Clear authentication
                    navigate('/');  // Redirect to Home page
                }}>
                    Logout
                </button>
            </div>
            <div className="dashboard-main-container">
                <div className='side-bar'>
                    <div onClick={() => toggleComponent(activeComponent)}>
                        Dashboard
                    </div>
                   <div onClick={() => toggleComponent('CreateUser')}>
                        Create User
                    </div>
                    <div onClick={() => toggleComponent('AddCompany')}>
                        Add Company
                    </div>
                    <div onClick={() => toggleComponent('CreateQuatation')}>
                        Create Quatation
                    </div>
                    <div onClick={() => toggleComponent('AddProduct')}>
                        Add Product
                    </div>
                    <div onClick={() => toggleComponent('QuatationList')}>
                        All Bills
                    </div>
                    <div onClick={() => toggleComponent('AddClient')}>
                        Add Client
                    </div>
                    <div onClick={() => {
                        onLogout();  // Clear authentication
                          // Redirect to Home page
                    }}>
                        Logout
                    </div>
                </div>

                <div className='main-sec'>
                    {/* <div>Select Company</div> */}
                    {
                        activeComponent==null && <div className="company-section">
                        <label>Select Company:</label>
                        <select onChange={handleCompanyChange}>
                          <option value="">Select a company</option>
                          {companies.map(company => (
                            <option key={company.id} value={company.id}>{company.companyName}</option>
                          ))}
                        </select>
                        {selectedCompany && (
                          <div className="company-details">
                            <p><strong>Name:</strong> {selectedCompany.companyName}</p>
                            <p><strong>GST No:</strong> {selectedCompany.gstNo}</p>
                            <p><strong>Email:</strong> {selectedCompany.email}</p>
                            <p><strong>Phone:</strong> {selectedCompany.phone}</p>
                            <p><strong>Address:</strong> {selectedCompany.address}</p>
                            {/* {billType=="pi" && <div>
                              <p><strong>Account Name:</strong> {selectedCompany.accountName}</p>
                              <p><strong>Bank Name:</strong> {selectedCompany.bankName}</p>
                              <p><strong>Account Number:</strong> {selectedCompany}</p>
                              </div>} */}
                          </div>
                        )}
                      </div>
                    }
                    {(userRole === 'SUPERADMIN' || userRole==='ADMIN') && activeComponent === 'CreateUser' && <CreateUser toggleComponent={toggleComponent} userRole={userRole}/>}
                    {activeComponent === 'AddCompany' && <AddCompany toggleComponent={toggleComponent}/>}
                    {activeComponent === 'CreateQuatation' && <CreateQuatation toggleComponent={toggleComponent} selectedCompany={selectedCompany}  />}
                    {activeComponent === 'AddProduct' && <AddProduct toggleComponent={toggleComponent}/>}
                    {activeComponent === 'QuatationList' && <QuotationList toggleComponent={toggleComponent} selectedCompany={selectedCompany}/>}
                    {activeComponent === 'AddClient' && <AddClient toggleComponent={toggleComponent} selectedCompany={selectedCompany}/>}
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default Dashboard;
