import React, { useEffect } from 'react'

function Test({selectedQuatation}) {

    useEffect(()=>{
        console.log(selectedQuatation)
    },[])
  return (
    <div>Test</div>
  )
}

export default Test;