import React from 'react'

const Button = ({ type = 'button', className = '', children, ...props }) => {
  return (
    <button
      type={type}
      className={`px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition duration-300 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 ${className}`}
      {...props}
    >
      {children}
    </button>
  )
}

export default Button
