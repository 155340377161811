// import React, { useState, useEffect } from 'react';
// import { createAdmin } from '../../utils/createAdmin';
// import { useNavigate } from 'react-router-dom';

// const CreateUser = ({ toggleComponent, userRole }) => {
//   const [email, setEmail] = useState('');
//   const [name, setName] = useState('');
//   const [password, setPassword] = useState('');
//   const [role, setRole] = useState('USER');
//   const [error, setError] = useState('');
//   const [success, setSuccess] = useState('');
//   const navigate = useNavigate();
//   const [availableRoles, setAvailableRoles] = useState([]);

//   useEffect(() => {
//     if (userRole == 'SUPERADMIN') {
//       setAvailableRoles(['USER', 'ADMIN', 'SUPERADMIN']);
//     } else if (userRole === 'ADMIN') {
//       setAvailableRoles(['USER', 'ADMIN']);
//     }
//     console.log('currentUserRole:', userRole); // Debugging line
//     console.log('Available roles:', availableRoles);  // Debugging line
//   }, [userRole]);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       await createAdmin(email, name, password, role);
//       setSuccess('User created successfully');
//       setTimeout(() => {
//         toggleComponent('CreateUser');
//       }, 5000);
//     } catch (err) {
//       setError('Failed to create user');
//     }
//   };

//   return (
//     <div>
//       <h2>Create User</h2>
//       <form onSubmit={handleSubmit}>
//         <div>
//           <label>Email:</label>
//           <input
//             type="text"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             required
//           />
//         </div>
//         <div>
//           <label>Name:</label>
//           <input
//             type="text"
//             value={name}
//             onChange={(e) => setName(e.target.value)}
//             required
//           />
//         </div>
//         <div>
//           <label>Password:</label>
//           <input
//             type="password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             required
//           />
//         </div>
//         <div>
//           <label>Role:</label>
//           <select value={role} onChange={(e) => setRole(e.target.value)} required>
//             {availableRoles.map((roleOption) => (
//               <option key={roleOption} value={roleOption}>
//                 {roleOption}
//               </option>
//             ))}
//           </select>
//         </div>
//         <button type="submit">Create User</button>
//         {error && <p style={{ color: 'red' }}>{error}</p>}
//         {success && <p style={{ color: 'green' }}>{success}</p>}
//       </form>
//     </div>
//   );
// };

// export default CreateUser;
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const CreateUser = () => {
//   const [email, setEmail] = useState('');
//   const [name, setName] = useState('');
//   const [password, setPassword] = useState('');
//   const [role, setRole] = useState('USER'); // default to USER
//   const [permissions, setPermissions] = useState([]);
//   const [selectedPermissions, setSelectedPermissions] = useState([]);
//   const [companies, setCompanies] = useState([]);
//   const [selectedCompanies, setSelectedCompanies] = useState([]);
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//   const [userData,setUserData]=useState(null);

//   // Fetch permissions and companies on component mount
//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     // Fetch permissions from API
//     axios.get('https://emkayindia.in/api/v1/permission/all', {
//       headers: {
//         'Authorization': `Bearer ${token}`
//       }
//     })
//       .then(response => setPermissions(response.data))
//       .catch(error => console.error('Error fetching permissions:', error));

//     // Fetch companies from API
//     axios.get('https://emkayindia.in/api/v1/company/all', {
//       headers: {
//         'Authorization': `Bearer ${token}`
//       }
//     }).then(response => {
//       setCompanies(response.data);
//     })
//   }, []);

//   const handlePermissionChange = (permissionId) => {
//     setSelectedPermissions(prevSelected => {
//       if (prevSelected.includes(permissionId)) {
//         return prevSelected.filter(id => id !== permissionId);
//       } else {
//         return [...prevSelected, permissionId];
//       }
//     });
//   };

//   const handleCompanyChange = (companyId) => {
//     setSelectedCompanies(prevSelected => {
//       if (prevSelected.includes(companyId)) {
//         return prevSelected.filter(id => id !== companyId);
//       } else {
//         return [...prevSelected, companyId];
//       }
//     });
//   };
//   const toggleDropdown = () => {
//     setIsDropdownOpen(!isDropdownOpen);
// };
// const handleCheckboxChange = (e) => {
//   const { value, checked } = e.target;
//   setSelectedCompanies(prevState => ({
//       ...prevState,
//       companyId: checked
//           ? [...prevState.companyId, value]
//           : prevState.companyId.filter(id => id !== value)
//   }));
// };

//   const handleSubmit = (e) => {
//     const token = localStorage.getItem('token');
//     e.preventDefault();
//     const userData = {
//       email,
//       name,
//       password,
//       role,
//       permission: selectedPermissions,
//       companies: selectedCompanies,
//     };
//     // setUserData(userData)

//     axios.post('https://emkayindia.in/api/v1/user/create', userData, {
//       headers: {
//         'Authorization': `Bearer ${token}`
//       }
//     })
//       .then(response => console.log('User created successfully:', response.data))
//       .catch(error => console.error('Error creating user:', error));
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <div>
//         <label>Email:</label>
//         <input
//           type="email"
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//           required
//         />
//       </div>
//       <div>
//         <label>Name:</label>
//         <input
//           type="text"
//           value={name}
//           onChange={(e) => setName(e.target.value)}
//           required
//         />
//       </div>
//       <div>
//         <label>Password:</label>
//         <input
//           type="password"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//           required
//         />
//       </div>
//       <div>
//         <label>Role:</label>
//         <select value={role} onChange={(e) => setRole(e.target.value)}>
//           <option value="USER">USER</option>
//           <option value="ADMIN">ADMIN</option>
//           <option value="SUPERADMIN">SUPERADMIN</option>
//         </select>
//       </div>

//       <div>
//         <label>Permissions:</label>
//         {permissions.map(permission => (
//           <div key={permission.id}>
//             <input
//               type="checkbox"
//               value={permission.id}
//               onChange={() => handlePermissionChange(permission.id)}
//             />
//             {permission.name}
//           </div>
//         ))}
//       </div>

//       <div className="dropdown">
//                 <button className="dropdown-button" onClick={toggleDropdown}>
//                     Select Companies
//                 </button>
//                 {isDropdownOpen && (
//                     <div className="dropdown-content">
//                         {companies.map(company => (
//                             <div>
//                                 <label >
//                                     <input
//                                         type="checkbox"
//                                         value={company.id}
//                                         onChange={handleCheckboxChange}
//                                     />
//                                 </label>
//                                 <p className='company-name'>{company.companyName}</p>

//                             </div>
//                         ))}
//                     </div>
//                 )}
//             </div>
//       <button type="submit">Create User</button>
//     </form>
//   );
// };

// export default CreateUser;
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const CreateUser = () => {
//   const [email, setEmail] = useState('');
//   const [name, setName] = useState('');
//   const [password, setPassword] = useState('');
//   const [role, setRole] = useState('USER'); // default to USER
//   const [permissions, setPermissions] = useState([]);
//   const [selectedPermissions, setSelectedPermissions] = useState([]);
//   const [companies, setCompanies] = useState([]);
//   const [selectedCompanies, setSelectedCompanies] = useState([]);
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);

//   // Fetch permissions and companies on component mount
//   useEffect(() => {
//     const token = localStorage.getItem('token');

//     // Fetch permissions from API
//     axios.get('https://emkayindia.in/api/v1/permission/all', {
//       headers: {
//         'Authorization': `Bearer ${token}`
//       }
//     })
//       .then(response => setPermissions(response.data))
//       .catch(error => console.error('Error fetching permissions:', error));

//     // Fetch companies from API
//     axios.get('https://emkayindia.in/api/v1/company/all', {
//       headers: {
//         'Authorization': `Bearer ${token}`
//       }
//     }).then(response => setCompanies(response.data))
//       .catch(error => console.error('Error fetching companies:', error));
//   }, []);

//   const handlePermissionChange = (permissionId) => {
//     setSelectedPermissions(prevSelected => {
//       if (prevSelected.includes(permissionId)) {
//         return prevSelected.filter(id => id !== permissionId);
//       } else {
//         return [...prevSelected, permissionId];
//       }
//     });
//   };

//   const handleCompanyChange = (companyId) => {
//     setSelectedCompanies(prevSelected => {
//       if (prevSelected.includes(companyId)) {
//         return prevSelected.filter(id => id !== companyId);
//       } else {
//         return [...prevSelected, companyId];
//       }
//     });
//   };

//   const toggleDropdown = () => {
//     setIsDropdownOpen(!isDropdownOpen);
//   };

//   const handleSubmit = (e) => {
//     const token = localStorage.getItem('token');
//     e.preventDefault();

//     const userData = {
//       email,
//       name,
//       password,
//       role,
//       permission: selectedPermissions,
//       companies: selectedCompanies,
//     };

//     axios.post('https://emkayindia.in/api/v1/user/create', userData, {
//       headers: {
//         'Authorization': `Bearer ${token}`
//       }
//     })
//       .then(response => console.log('User created successfully:', response.data))
//       .catch(error => console.error('Error creating user:', error));
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <div>
//         <label>Email:</label>
//         <input
//           type="email"
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//           required
//         />
//       </div>
//       <div>
//         <label>Name:</label>
//         <input
//           type="text"
//           value={name}
//           onChange={(e) => setName(e.target.value)}
//           required
//         />
//       </div>
//       <div>
//         <label>Password:</label>
//         <input
//           type="password"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//           required
//         />
//       </div>
//       <div>
//         <label>Role:</label>
//         <select value={role} onChange={(e) => setRole(e.target.value)}>
//           <option value="USER">USER</option>
//           <option value="ADMIN">ADMIN</option>
//           <option value="SUPERADMIN">SUPERADMIN</option>
//         </select>
//       </div>

//       <div>
//         <label>Permissions:</label>
//         {permissions.map(permission => (
//           <div key={permission.id}>
//             <input
//               type="checkbox"
//               value={permission.id}
//               onChange={() => handlePermissionChange(permission.id)}
//             />
//             {permission.name}
//           </div>
//         ))}
//       </div>

//       <div className="dropdown">
//         <button type="button" className="dropdown-button" onClick={toggleDropdown}>
//           Select Companies
//         </button>
//         {isDropdownOpen && (
//           <div className="dropdown-content">
//             {companies.map(company => (
//               <div key={company.id}>
//                 <label>
//                   <input
//                     type="checkbox"
//                     value={company.id}
//                     checked={selectedCompanies.includes(company.id)}
//                     onChange={() => handleCompanyChange(company.id)}
//                   />
//                   {company.companyName}
//                 </label>
//               </div>
//             ))}
//           </div>
//         )}
//       </div>

//       <button type="submit">Create User</button>
//     </form>
//   );
// };

// export default CreateUser;
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// const CreateUser = ({toggleComponent}) => {
//   const [email, setEmail] = useState('');
//   const [name, setName] = useState('');
//   const [password, setPassword] = useState('');
//   const [role, setRole] = useState('USER'); // default to USER
//   const [permissions, setPermissions] = useState([]);
//   const [selectedPermissions, setSelectedPermissions] = useState([]);
//   const [companies, setCompanies] = useState([]);
//   const [selectedCompanies, setSelectedCompanies] = useState([]);
//   const [isCompanyDropdownOpen, setIsCompanyDropdownOpen] = useState(false);
//   const [isPermissionDropdownOpen, setIsPermissionDropdownOpen] = useState(false);


//   // Fetch permissions and companies on component mount
//   useEffect(() => {
//     const token = localStorage.getItem('token');

//     // Fetch permissions from API
//     axios.get('https://emkayindia.in/api/v1/permission/all', {
//       headers: {
//         'Authorization': `Bearer ${token}`
//       }
//     })
//       .then(response => setPermissions(response.data))
//       .catch(error => console.error('Error fetching permissions:', error));

//     // Fetch companies from API
//     axios.get('https://emkayindia.in/api/v1/company/all', {
//       headers: {
//         'Authorization': `Bearer ${token}`
//       }
//     }).then(response => setCompanies(response.data))
//       .catch(error => console.error('Error fetching companies:', error));
//   }, []);

//   const handlePermissionChange = (permissionId) => {
//     setSelectedPermissions(prevSelected => {
//       if (prevSelected.includes(permissionId)) {
//         return prevSelected.filter(id => id !== permissionId);
//       } else {
//         return [...prevSelected, permissionId];
//       }
//     });
//   };

//   const handleCompanyChange = (companyId) => {
//     setSelectedCompanies(prevSelected => {
//       if (prevSelected.includes(companyId)) {
//         return prevSelected.filter(id => id !== companyId);
//       } else {
//         return [...prevSelected, companyId];
//       }
//     });
//   };

//   const toggleCompanyDropdown = () => {
//     setIsCompanyDropdownOpen(!isCompanyDropdownOpen);
//   };

//   const togglePermissionDropdown = () => {
//     setIsPermissionDropdownOpen(!isPermissionDropdownOpen);
//   };

//   const handleSubmit = (e) => {
//     const token = localStorage.getItem('token');
//     e.preventDefault();

//     const userData = {
//       email,
//       name,
//       password,
//       role,
//       permission: selectedPermissions,
//       companies: selectedCompanies,
//     };

//     axios.post('https://emkayindia.in/api/v1/user/create', userData, {
//       headers: {
//         'Authorization': `Bearer ${token}`
//       }
//     })
//       .then(response => {
//         toast.success('User created successfully!');
//         setTimeout(() => {

//         }, 2000);
//         toggleComponent("CreateUser")
//         console.log('User created successfully:', response.data)
//       })
//       .catch(error => {
//         toast.error(`Error creating user: ${error.response?.data?.message || 'Unknown error'}`); // Show error toast with message
//         console.error('Error creating user:', error);
//       });
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <div>
//         <label>Email:</label>
//         <input
//           type="text"
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//           required
//         />
//       </div>
//       <div>
//         <label>Name:</label>
//         <input
//           type="text"
//           value={name}
//           onChange={(e) => setName(e.target.value)}
//           required
//         />
//       </div>
//       <div>
//         <label>Password:</label>
//         <input
//           type="password"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//           required
//         />
//       </div>
//       <div>
//         <label>Role:</label>
//         <select value={role} onChange={(e) => setRole(e.target.value)}>
//           <option value="USER">USER</option>
//           <option value="ADMIN">ADMIN</option>
//           <option value="SUPERADMIN">SUPERADMIN</option>
//         </select>
//       </div>

//       <div className="dropdown">
//         <button type="button" className="dropdown-button" onClick={togglePermissionDropdown}>
//           Select Permissions
//         </button>
//         {isPermissionDropdownOpen && (
//           <div className="dropdown-content">
//             {permissions.map(permission => (
//               <div key={permission.id}>
//                 <label>
//                   <input
//                     type="checkbox"
//                     value={permission.id}
//                     checked={selectedPermissions.includes(permission.id)}
//                     onChange={() => handlePermissionChange(permission.id)}
//                   />
//                   {permission.name}
//                 </label>
//               </div>
//             ))}
//           </div>
//         )}
//       </div>

//       <div className="dropdown">
//         <button type="button" className="dropdown-button" onClick={toggleCompanyDropdown}>
//           Select Companies
//         </button>
//         {isCompanyDropdownOpen && (
//           <div className="dropdown-content">
//             {companies.map(company => (
//               <div key={company.id}>
//                 <label>
//                   <input
//                     type="checkbox"
//                     value={company.id}
//                     checked={selectedCompanies.includes(company.id)}
//                     onChange={() => handleCompanyChange(company.id)}
//                   />
//                   {company.companyName}
//                 </label>
//               </div>
//             ))}
//           </div>
//         )}
//       </div>

//       <button type="submit">Create User</button>
//       <ToastContainer />
//     </form>
//   );
// };

// export default CreateUser;
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// const CreateUser = ({ toggleComponent }) => {
//   const [email, setEmail] = useState('');
//   const [name, setName] = useState('');
//   const [password, setPassword] = useState('');
//   const [role, setRole] = useState('USER');
//   const [permissions, setPermissions] = useState([]);
//   const [selectedPermissions, setSelectedPermissions] = useState([]);
//   const [companies, setCompanies] = useState([]);
//   const [selectedCompanies, setSelectedCompanies] = useState([]);
//   const [address, setAddress] = useState('');
//   const [city, setCity] = useState('');
//   const [phone, setPhone] = useState('');
//   const [pincode, setPincode] = useState('');
//   const [isCompanyDropdownOpen, setIsCompanyDropdownOpen] = useState(false);
//   const [isPermissionDropdownOpen, setIsPermissionDropdownOpen] = useState(false);

//   useEffect(() => {
//     const token = localStorage.getItem('token');

//     // Fetch permissions from API
//     axios.get('https://emkayindia.in/api/v1/permission/all', {
//       headers: { 'Authorization': `Bearer ${token}` }
//     })
//       .then(response => setPermissions(response.data))
//       .catch(error => console.error('Error fetching permissions:', error));

//     // Fetch companies from API
//     axios.get('https://emkayindia.in/api/v1/company/all', {
//       headers: { 'Authorization': `Bearer ${token}` }
//     })
//       .then(response => setCompanies(response.data))
//       .catch(error => console.error('Error fetching companies:', error));
//   }, []);

//   const handlePermissionChange = (permissionId) => {
//     setSelectedPermissions(prevSelected =>
//       prevSelected.includes(permissionId)
//         ? prevSelected.filter(id => id !== permissionId)
//         : [...prevSelected, permissionId]
//     );
//   };

//   const handleCompanyChange = (companyId) => {
//     setSelectedCompanies(prevSelected =>
//       prevSelected.includes(companyId)
//         ? prevSelected.filter(id => id !== companyId)
//         : [...prevSelected, companyId]
//     );
//   };

//   const toggleCompanyDropdown = () => {
//     setIsCompanyDropdownOpen(!isCompanyDropdownOpen);
//   };

//   const togglePermissionDropdown = () => {
//     setIsPermissionDropdownOpen(!isPermissionDropdownOpen);
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const token = localStorage.getItem('token');

//     const userData = {
//       email,
//       name,
//       password,
//       role,
//       permission: selectedPermissions,
//       companies: selectedCompanies,
//       address,
//       city,
//       phone,
//       pincode
//     };

//     axios.post('https://emkayindia.in/api/v1/user/create', userData, {
//       headers: { 'Authorization': `Bearer ${token}` }
//     })
//       .then(response => {
//         toast.success('User created successfully!');
//         toggleComponent("CreateUser");
//       })
//       .catch(error => {
//         toast.error(`Error creating user: ${error.response?.data?.message || 'Unknown error'}`);
//         console.error('Error creating user:', error);
//       });
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <div>
//         <label>Email:</label>
//         <input
//           type="text"
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//           required
//         />
//       </div>
//       <div>
//         <label>Name:</label>
//         <input
//           type="text"
//           value={name}
//           onChange={(e) => setName(e.target.value)}
//           required
//         />
//       </div>
//       <div>
//         <label>Password:</label>
//         <input
//           type="password"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//           required
//         />
//       </div>
//       <div>
//         <label>Role:</label>
//         <select value={role} onChange={(e) => setRole(e.target.value)}>
//           <option value="USER">USER</option>
//           <option value="ADMIN">ADMIN</option>
//           <option value="SUPERADMIN">SUPERADMIN</option>
//         </select>
//       </div>

//       <div>
//         <label>Address:</label>
//         <input
//           type="text"
//           value={address}
//           onChange={(e) => setAddress(e.target.value)}
//           required
//         />
//       </div>
//       <div>
//         <label>City:</label>
//         <input
//           type="text"
//           value={city}
//           onChange={(e) => setCity(e.target.value)}
//           required
//         />
//       </div>
//       <div>
//         <label>Phone:</label>
//         <input
//           type="text"
//           value={phone}
//           onChange={(e) => setPhone(e.target.value)}
//           required
//         />
//       </div>
//       <div>
//         <label>Pincode:</label>
//         <input
//           type="text"
//           value={pincode}
//           onChange={(e) => setPincode(e.target.value)}
//           required
//         />
//       </div>

//       {/* Permissions Dropdown */}
//       <div className="dropdown">
//         <button type="button" className="dropdown-button" onClick={togglePermissionDropdown}>
//           Select Permissions
//         </button>
//         {isPermissionDropdownOpen && (
//           <div className="dropdown-content">
//             {permissions.map(permission => (
//               <div key={permission.id}>
//                 <label>
//                   <input
//                     type="checkbox"
//                     value={permission.id}
//                     checked={selectedPermissions.includes(permission.id)}
//                     onChange={() => handlePermissionChange(permission.id)}
//                   />
//                   {permission.name}
//                 </label>
//               </div>
//             ))}
//           </div>
//         )}
//       </div>

//       {/* Companies Dropdown */}
//       <div className="dropdown">
//         <button type="button" className="dropdown-button" onClick={toggleCompanyDropdown}>
//           Select Companies
//         </button>
//         {isCompanyDropdownOpen && (
//           <div className="dropdown-content">
//             {companies.map(company => (
//               <div key={company.id}>
//                 <label>
//                   <input
//                     type="checkbox"
//                     value={company.id}
//                     checked={selectedCompanies.includes(company.id)}
//                     onChange={() => handleCompanyChange(company.id)}
//                   />
//                   {company.companyName}
//                 </label>
//               </div>
//             ))}
//           </div>
//         )}
//       </div>

//       <button type="submit">Create User</button>
//       <ToastContainer />
//     </form>
//   );
// };

// export default CreateUser;
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import '../css/CreateUser.css'; // Add CSS import for your styles

// const CreateUser = ({ toggleComponent }) => {
//   const [email, setEmail] = useState('');
//   const [name, setName] = useState('');
//   const [password, setPassword] = useState('');
//   const [role, setRole] = useState('USER');
//   const [permissions, setPermissions] = useState([]);
//   const [selectedPermissions, setSelectedPermissions] = useState([]);
//   const [companies, setCompanies] = useState([]);
//   const [selectedCompanies, setSelectedCompanies] = useState([]);
//   const [address, setAddress] = useState('');
//   const [city, setCity] = useState('');
//   const [phone, setPhone] = useState('');
//   const [pincode, setPincode] = useState('');
//   const [isCompanyDropdownOpen, setIsCompanyDropdownOpen] = useState(false);
//   const [isPermissionDropdownOpen, setIsPermissionDropdownOpen] = useState(false);

//   useEffect(() => {
//     const token = localStorage.getItem('token');

//     // Fetch permissions from API
//     axios.get('https://emkayindia.in/api/v1/permission/all', {
//       headers: { 'Authorization': `Bearer ${token}` }
//     })
//       .then(response => setPermissions(response.data))
//       .catch(error => console.error('Error fetching permissions:', error));

//     // Fetch companies from API
//     axios.get('https://emkayindia.in/api/v1/company/all', {
//       headers: { 'Authorization': `Bearer ${token}` }
//     })
//       .then(response => setCompanies(response.data))
//       .catch(error => console.error('Error fetching companies:', error));
//   }, []);

//   const handlePermissionChange = (permissionId) => {
//     setSelectedPermissions(prevSelected =>
//       prevSelected.includes(permissionId)
//         ? prevSelected.filter(id => id !== permissionId)
//         : [...prevSelected, permissionId]
//     );
//   };

//   const handleCompanyChange = (companyId) => {
//     setSelectedCompanies(prevSelected =>
//       prevSelected.includes(companyId)
//         ? prevSelected.filter(id => id !== companyId)
//         : [...prevSelected, companyId]
//     );
//   };

//   const sortedPermissions = [...permissions].sort((a, b) => {
//     const isASelected = selectedPermissions.includes(a.id);
//     const isBSelected = selectedPermissions.includes(b.id);
//     return isASelected === isBSelected ? 0 : isASelected ? -1 : 1;
//   });

//   const sortedCompanies = [...companies].sort((a, b) => {
//     const isASelected = selectedCompanies.includes(a.id);
//     const isBSelected = selectedCompanies.includes(b.id);
//     return isASelected === isBSelected ? 0 : isASelected ? -1 : 1;
//   });

//   const toggleCompanyDropdown = () => {
//     setIsCompanyDropdownOpen(!isCompanyDropdownOpen);
//   };

//   const togglePermissionDropdown = () => {
//     setIsPermissionDropdownOpen(!isPermissionDropdownOpen);
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const token = localStorage.getItem('token');

//     const userData = {
//       email,
//       name,
//       password,
//       role,
//       permission: selectedPermissions,
//       companies: selectedCompanies,
//       address,
//       city,
//       phone,
//       pincode
//     };

//     axios.post('https://emkayindia.in/api/v1/user/create', userData, {
//       headers: { 'Authorization': `Bearer ${token}` }
//     })
//       .then(response => {
//         toast.success('User created successfully!');
//         toggleComponent("CreateUser");
//       })
//       .catch(error => {
//         toast.error(`Error creating user: ${error.response?.data?.message || 'Unknown error'}`);
//         console.error('Error creating user:', error);
//       });
//   };

//   return (
//     <form onSubmit={handleSubmit} className="create-user-form">
//       {/* Permissions Dropdown */}
//       <div className="dropdown">
//         <button type="button" className="dropdown-button" onClick={togglePermissionDropdown}>
//           Select Permissions
//         </button>
//         {isPermissionDropdownOpen && (
//           <div className="dropdown-content">
//             {sortedPermissions.map(permission => (
//               <div key={permission.id} className="dropdown-item">
//                 <label>
//                   <input
//                     type="checkbox"
//                     value={permission.id}
//                     checked={selectedPermissions.includes(permission.id)}
//                     onChange={() => handlePermissionChange(permission.id)}
//                   />
//                   {permission.name}
//                 </label>
//               </div>
//             ))}
//           </div>
//         )}
//       </div>

//       {/* Companies Dropdown */}
//       <div className="dropdown">
//         <button type="button" className="dropdown-button" onClick={toggleCompanyDropdown}>
//           Select Companies
//         </button>
//         {isCompanyDropdownOpen && (
//           <div className="dropdown-content">
//             {sortedCompanies.map(company => (
//               <div key={company.id} className="dropdown-item">
//                 <label>
//                   <input
//                     type="checkbox"
//                     value={company.id}
//                     checked={selectedCompanies.includes(company.id)}
//                     onChange={() => handleCompanyChange(company.id)}
//                   />
//                   {company.companyName}
//                 </label>
//               </div>
//             ))}
//           </div>
//         )}
//       </div>
//       <div className="form-group">
//         <label>Email:</label>
//         <input
//           type="text"
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//           required
//         />
//       </div>
//       <div className="form-group">
//         <label>Name:</label>
//         <input
//           type="text"
//           value={name}
//           onChange={(e) => setName(e.target.value)}
//           required
//         />
//       </div>
//       <div className="form-group">
//         <label>Password:</label>
//         <input
//           type="password"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//           required
//         />
//       </div>
//       <div className="form-group">
//         <label>Role:</label>
//         <select value={role} onChange={(e) => setRole(e.target.value)}>
//           <option value="USER">USER</option>
//           <option value="ADMIN">ADMIN</option>
//           <option value="SUPERADMIN">SUPERADMIN</option>
//         </select>
//       </div>

//       <div className="form-group">
//         <label>Address:</label>
//         <input
//           type="text"
//           value={address}
//           onChange={(e) => setAddress(e.target.value)}
//           required
//         />
//       </div>
//       <div className="form-group">
//         <label>City:</label>
//         <input
//           type="text"
//           value={city}
//           onChange={(e) => setCity(e.target.value)}
//           required
//         />
//       </div>
//       <div className="form-group">
//         <label>Phone:</label>
//         <input
//           type="text"
//           value={phone}
//           onChange={(e) => setPhone(e.target.value)}
//           required
//         />
//       </div>
//       <div className="form-group">
//         <label>Pincode:</label>
//         <input
//           type="text"
//           value={pincode}
//           onChange={(e) => setPincode(e.target.value)}
//           required
//         />
//       </div>



//       <button type="submit" className="submit-button">Create User</button>
//       <ToastContainer />
//     </form>
//   );
// };

// export default CreateUser;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../css/CreateUser.css'; // Import the CSS file

const CreateUser = ({ toggleComponent }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('USER');
  const [permissions, setPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [isCompanyDropdownOpen, setIsCompanyDropdownOpen] = useState(false);
  const [isPermissionDropdownOpen, setIsPermissionDropdownOpen] = useState(false);
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [phone, setPhone] = useState('');
  const [pincode, setPincode] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');

    axios.get('https://emkayindia.in/api/v1/permission/all', {
      headers: { 'Authorization': `Bearer ${token}` }
    })
      .then(response => setPermissions(response.data))
      .catch(error => console.error('Error fetching permissions:', error));

    axios.get('https://emkayindia.in/api/v1/company/all', {
      headers: { 'Authorization': `Bearer ${token}` }
    })
      .then(response => setCompanies(response.data))
      .catch(error => console.error('Error fetching companies:', error));
  }, []);

  const handlePermissionChange = (permissionId) => {
    setSelectedPermissions(prevSelected => {
      if (prevSelected.includes(permissionId)) {
        return prevSelected.filter(id => id !== permissionId);
      } else {
        return [...prevSelected, permissionId];
      }
    });
  };

  const handleCompanyChange = (companyId) => {
    setSelectedCompanies(prevSelected => {
      if (prevSelected.includes(companyId)) {
        return prevSelected.filter(id => id !== companyId);
      } else {
        return [...prevSelected, companyId];
      }
    });
  };

  const toggleCompanyDropdown = () => {
    setIsCompanyDropdownOpen(!isCompanyDropdownOpen);
  };

  const togglePermissionDropdown = () => {
    setIsPermissionDropdownOpen(!isPermissionDropdownOpen);
  };

  const handleSubmit = (e) => {
    const token = localStorage.getItem('token');
    e.preventDefault();

    const userData = {
      email,
      name,
      password,
      role,
      permission: selectedPermissions,
      companies: selectedCompanies,
      address,
      city,
      phone,
      pincode,
    };

    axios.post('https://emkayindia.in/api/v1/user/create', userData, {
      headers: { 'Authorization': `Bearer ${token}` }
    })
      .then(response => {
        toast.success('User created successfully!');
        toggleComponent("CreateUser");
        console.log('User created successfully:', response.data);
      })
      .catch(error => {
        toast.error(`Error creating user: ${error.response?.data?.message || 'Unknown error'}`);
        console.error('Error creating user:', error);
      });
  };

  return (
    <form onSubmit={handleSubmit} className="create-user-form">
      <div className='create-user-top'>
        <h2>Create User</h2>
        <button type="submit" className="submit-button">Save User</button>
      </div>

      <div>
        <h3>Personal Details</h3>
      </div>
      <div className="section personal-details">
        <div>
          <div className='input-sec'>
            <label>Email:</label>
            <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} required className="input-field" />
          </div>
          <div className='input-sec'>
            <label>Phone:</label>
            <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} required className="input-field" />
          </div>
          <div className='input-sec'>
            <label>Pincode:</label>
            <input type="text" value={pincode} onChange={(e) => setPincode(e.target.value)} required className="input-field" />
          </div>
        </div>
        <div>
          <div className='input-sec'>
            <label>Name:</label>
            <input type="text" value={name} onChange={(e) => setName(e.target.value)} required className="input-field" />
          </div>
          <div className='input-sec'>
            <label>Address:</label>
            <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} required className="input-field" />
          </div>
        </div>
        <div>
          <div className='input-sec'>
            <label>Password:</label>
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required className="input-field" />
          </div>
          <div className='input-sec'>
            <label>City:</label>
            <input type="text" value={city} onChange={(e) => setCity(e.target.value)} required className="input-field" />
          </div>
        </div>
      </div>
      <div>
        <h3>Work Details</h3>
      </div>
      <div className="section work-details">
        <div className="dropdown-section">
          <div className="role-section">
            <div className="role-dropdown">
              <label htmlFor="role" className="role-label">Role:</label>
              <select id="role" value={role} onChange={(e) => setRole(e.target.value)} className="dropdown-select">
                <option value="USER">USER</option>
                <option value="ADMIN">ADMIN</option>
                <option value="SUPERADMIN">SUPERADMIN</option>
              </select>
            </div>
          </div>
          <div className="dropdown ">
            <div className='permission-sec'>
              <label>Permissions:</label>
              <button
                type="button"
                className="dropdown-button"
                onClick={togglePermissionDropdown} // Toggle the dropdown on click
              >
                Select Permissions {selectedPermissions.length > 0 && `(${selectedPermissions.length})`}
              </button>

              {isPermissionDropdownOpen && (
                <div className="dropdown-content">
                  {permissions.map(permission => (
                    <div key={permission.id}>
                      <label>
                        <input
                          type="checkbox"
                          value={permission.id}
                          checked={selectedPermissions.includes(permission.id)}
                          onChange={() => handlePermissionChange(permission.id)}
                        />
                        {permission.name}
                      </label>
                    </div>
                  ))}

                </div>


              )}
            </div>
          </div>


          <div className="dropdown">
            <label>Companies:</label>
            <button type="button" className="dropdown-button" onClick={toggleCompanyDropdown}>
              Select Companies {selectedCompanies.length > 0 && `(${selectedCompanies.length})`}
            </button>
            {isCompanyDropdownOpen && (
              <div className="dropdown-content">
                {companies.map(company => (
                  <div key={company.id}>
                    <label>
                      <input
                        type="checkbox"
                        value={company.id}
                        checked={selectedCompanies.includes(company.id)}
                        onChange={() => handleCompanyChange(company.id)}
                      />
                      {company.companyName}
                    </label>
                  </div>
                ))}
              </div>
            )}
          </div>


        </div>
        <div className="selected-values">
          {selectedPermissions.length > 0 && (
            <div className="selected-permissions">
              <h4>Selected Permissions:</h4>
              <ul>
                {selectedPermissions.map(permissionId => {
                  const permission = permissions.find(p => p.id === permissionId);
                  return <li key={permissionId}>{permission.name}</li>;
                })}
              </ul>
            </div>
          )}

          {selectedCompanies.length > 0 && (
            <div className="selected-companies">
              <h4>Selected Companies:</h4>
              <ul>
                {selectedCompanies.map(companyId => {
                  const company = companies.find(c => c.id === companyId);
                  return <li key={companyId}>{company.companyName}</li>;
                })}
              </ul>
            </div>
          )}
        </div>

      </div>

      {/* <button type="submit" className="submit-button">Create User</button> */}
      <ToastContainer />
    </form>
  );
};

export default CreateUser;
